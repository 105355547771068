import { Box, Text } from "@chakra-ui/react";

import "./CommonTitle.scss";

type CommonTitleProps = {
  titleMeta?: string;
  mainTitle: string;
  subText: string;
  versionTextStyleClass?: string;
  mainTitleStyleClass?: string;
  subTextStyleClass?: string;
};

const CommonTitle = (props: CommonTitleProps) => {
  const {
    titleMeta = "",
    mainTitle,
    subText,
    versionTextStyleClass,
    mainTitleStyleClass,
    subTextStyleClass,
  } = props;

  return (
    <Box className="common-title">
      {titleMeta.trim() && (
        <Text className={`common-version-text ${versionTextStyleClass}`}>
          {titleMeta.trim()}
        </Text>
      )}
      <Text className={`common-main-title ${mainTitleStyleClass}`} as="h3">
        {mainTitle}
      </Text>
      <Text className={`common-sub-text ${subTextStyleClass}`}>{subText}</Text>
    </Box>
  );
};

export default CommonTitle;
