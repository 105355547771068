"use client";

import { SelectDropdown, RadioButtonGroup, InputText } from "../../common";
import { RadioButtonGroupProps } from "../../common/form-fields/radio-button-group/RadioButtonGroup";
import "./CoupleRadioWithDropdown.scss";
import { Box, Flex } from "@/components/ChakraUiManager";
import { useMemo, useState } from "react";

export interface AddedComponentTypeValue {
  componentName: string;
  version: number;
  componentClass: string;
  isExisting?: boolean;
}
export type ExistingComponentMap = Record<string, Record<string, number[]>>;

export type AddComponentInputOptionType = "PRIMARY" | "SECONDARY";
export interface AddComponentInputWithDropdownProps
  extends Omit<
    RadioButtonGroupProps<any>,
    "radioPropList" | "value" | "onChange"
  > {
  value: AddedComponentTypeValue | null;
  onChange: (value: AddedComponentTypeValue | null) => void;
  componentClass: string;
  componentMapOptions: ExistingComponentMap[string];
}

export default function AddComponentInput(
  props: AddComponentInputWithDropdownProps
) {
  const { value, onChange, componentClass, componentMapOptions, ...rest } =
    props;

  const PRIMARY_RADIO_VALUE: AddComponentInputOptionType = "PRIMARY";
  const SECONDARY_RADIO_VALUE: AddComponentInputOptionType = "SECONDARY";

  const [radioValue, setRadioValue] = useState<string>(
    value?.version != null
      ? PRIMARY_RADIO_VALUE
      : value?.componentName
        ? SECONDARY_RADIO_VALUE
        : ""
  );

  const componentOptions = useMemo(() => {
    return Object.keys(componentMapOptions || {});
  }, [componentMapOptions]);

  const versionOptions = useMemo(() => {
    return value?.componentName &&
      componentMapOptions &&
      Object.keys(componentMapOptions)?.length
      ? componentMapOptions?.[value.componentName]
      : [];
  }, [componentMapOptions, value]);

  const handleRadioChange = (value: string) => {
    setRadioValue(value);
    onChange(null);
  };

  const handleNewComponent = (value: string) => {
    onChange({
      componentClass,
      componentName: value,
      version: 1, // new component will always have version as 1
    });
  };

  const handleComponentSelect = (value: string) => {
    onChange({
      componentClass,
      componentName: value,
      version: -1,
      isExisting: true,
    });
  };

  const handleVersion = (version: number) => {
    onChange({
      ...value!,
      version,
    });
  };

  return (
    <Box className="couple-radio-dropdown-container">
      <RadioButtonGroup
        {...rest}
        value={radioValue}
        spacing={"3rem"}
        radioPropList={[
          {
            label: "Select an existing component",
            value: PRIMARY_RADIO_VALUE,
          },
          {
            label: "Create a new component",
            value: SECONDARY_RADIO_VALUE,
          },
        ]}
        onChange={handleRadioChange}
        isBoxedRadio={true}
      />

      {radioValue === PRIMARY_RADIO_VALUE ? (
        <>
          <Flex gap={6.4} className="dashed-left-border couple-radio-label">
            <Box flexBasis="80%">
              <SelectDropdown
                customClass="couple-radio-dropdown"
                dropdownList={componentOptions}
                value={value?.componentName ?? ""}
                onChange={handleComponentSelect}
                placeholder="Select component"
                hideDashedBorder
                clearable={false}
                error={rest.error}
              />
            </Box>
            <Box flexBasis="20%">
              <SelectDropdown
                dropdownList={versionOptions}
                value={value?.version ?? -1}
                onChange={handleVersion}
                placeholder="Version"
                hideDashedBorder
                clearable={false}
                error={rest.error}
              />
            </Box>
          </Flex>
        </>
      ) : radioValue === SECONDARY_RADIO_VALUE ? (
        <Box mt={6.4} className="dashed-left-border">
          <InputText
            value={value?.componentName ?? ""}
            onChange={handleNewComponent}
            placeholder="Enter a component name"
            error={rest.error}
          />
        </Box>
      ) : null}
    </Box>
  );
}
