"use client";
import { useContext, useEffect, useState } from "react";
import "./ConfigInterstitialScreen.scss";
import Image from "next/image";
import { blueComplete } from "public/assets";
import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import {
  QUERY_PARAM_KEY,
  ROUTE_BASE_URL,
  ROUTE_PATH,
} from "@/route-config/route-path";
import { useRouter, useSearchParams } from "next/navigation";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import StatusBubble from "@/components/common/status-bubble/StatusBubble";
import { AppPortal, ProductTitle } from "@/components/common";
import {
  DEFAULT_ADDITIONAL_PRODUCT_ATTRIBUTES,
  DEPOSIT_COMPONENT_CLASS_FOR_SIDEBAR,
  LOAN_COMPONENT_CLASS_FOR_SIDEBAR,
} from "@/data/status-sidebar-menu-data";
import {
  getFeatureStatus,
  isProductTypeIsLoan,
} from "../../product-management-util-service";
import { ProductComponentType } from "../../model/product-models";
import {
  RECOMENDED_PRODUCT_FEATURES,
  PRODUCT_IFX_TYPE_TITLE,
  DASHBOARD_ACCORDION_STATUS,
} from "@/utils/constants";
import { getCompleteAttributeList } from "../../product-summary/product-summary-config";
import { deepCopy } from "@finxact/finxact-shared-ui";
import { useEffectOnce } from "react-use";
import AppBodyGridWithHeader from "@/components/layout/AppBodyGridWithHeader/AppBodyGridWithHeader";

type Props = {
  type: "component" | "product";
};

const ConfigInterstitialScreen = (props: Props) => {
  const {
    productDetails,
    productWorkflowData,
    setProductRefetchState,
    navigateWithProductNameParam,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const router = useRouter();
  const [recomendedComponents, setRecomendedComponents] = useState<
    ProductComponentType[]
  >([]);
  const [nonRecomendedComponents, setNonRecomendedComponents] = useState<
    ProductComponentType[]
  >([]);
  const queryParam = useSearchParams();
  const currProductId = queryParam.get(QUERY_PARAM_KEY.PRODUCT_NAME_KEY);

  const currentProductTypeComponentClass = isProductTypeIsLoan(productDetails)
    ? LOAN_COMPONENT_CLASS_FOR_SIDEBAR
    : DEPOSIT_COMPONENT_CLASS_FOR_SIDEBAR;

  function constructTopBannerSubTitle(): string {
    const subTitle = `${
      productDetails?.ifxAcctType
        ? PRODUCT_IFX_TYPE_TITLE[productDetails?.ifxAcctType!]
        : ""
    } ${"Product"} |  Product attributes`.toUpperCase();
    return subTitle;
  }

  // Fetch product when user lands
  useEffectOnce(() => {
    setProductRefetchState(true);
  });

  function navigateToNextComponent() {
    const nextCompClass = productDetails?.components?.find(
      (item) => item.status !== DASHBOARD_ACCORDION_STATUS.completed
    )?.componentClass;
    const upcomingCompConfig = nextCompClass
      ? currentProductTypeComponentClass?.[nextCompClass]
      : null;
    let currRoute;
    if (upcomingCompConfig) {
      currRoute = upcomingCompConfig.subMenuList?.length
        ? upcomingCompConfig.subMenuList[0].href
        : upcomingCompConfig.href;
    } else if (
      productDetails?.components?.every(
        (item) => item.status === DASHBOARD_ACCORDION_STATUS.completed
      )
    ) {
      const attrList = getCompleteAttributeList(
        productDetails?.ifxAcctType ?? ""
      );
      if (attrList?.length && attrList[0].link) {
        currRoute = attrList[0].link;
      }
    }
    if (!currRoute?.includes(ROUTE_BASE_URL.DASHBOARD_PATH.replace("/", ""))) {
      currRoute = `${ROUTE_BASE_URL.DASHBOARD_PATH}${currRoute}`;
    }
    if (currRoute) {
      navigateWithProductNameParam?.(currRoute);
    }
  }

  useEffect(() => {
    if (productDetails?.ifxAcctType && productDetails.components?.length) {
      const recommendedProductsFeatures =
        deepCopy(RECOMENDED_PRODUCT_FEATURES[productDetails.ifxAcctType])?.map(
          (item) => item.componentClass
        ) || [];
      const recomendedComponentsList = productDetails.components.filter(
        (item) => recommendedProductsFeatures.includes(item.componentClass)
      );
      const nonRecommendedComponentList = productDetails.components.filter(
        (item) => !recommendedProductsFeatures.includes(item.componentClass)
      );
      setRecomendedComponents(recomendedComponentsList);
      setNonRecomendedComponents(nonRecommendedComponentList);
    }
  }, [productDetails?.components]);

  return (
    <>
      <AppBodyGridWithHeader>
        {productDetails?.name ? (
          <ProductTitle
            title={productDetails?.name || "[PRODUCT_NAME]"}
            subTitle={constructTopBannerSubTitle()}
          />
        ) : (
          ""
        )}
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          className="component-config-interstitial-screen-container"
        >
          <Image src={blueComplete} alt="Complete" />
          <Text as={"h3"} className="heading-text">
            {props.type === "component"
              ? "You’ve made it through the component portion of your configuration! Next up is Product Attributes."
              : "You’ve made it through the steps to configure the following component(s):"}
          </Text>
          {props.type === "component" && (
            <Text className="description-text">
              If you’d like to add another component, you can click the button
              below. You can always add more later via the Product Summary.
            </Text>
          )}
          <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            className="content-container"
          >
            <Text as={"h4"} className="content-heading-text">
              {productDetails?.name}
            </Text>
            <Text as={"h4"} className="content-sub-heading-text">
              Component Details
            </Text>
            <Flex direction="column">
              <Box pb={5}>
                {recomendedComponents?.length
                  ? recomendedComponents.map(
                      (component: ProductComponentType, index: number) => (
                        <Flex
                          key={`recommendedProducts${index}`}
                          className="items"
                          alignItems={"center"}
                          justifyContent={"flex-start"}
                        >
                          <StatusBubble
                            status={getFeatureStatus(component?.status || "")}
                          />
                          <Text>
                            {
                              currentProductTypeComponentClass[
                                component.componentClass
                              ]?.name
                            }
                          </Text>
                        </Flex>
                      )
                    )
                  : ""}
              </Box>
              {recomendedComponents?.length > 0 ? <AddIcon /> : ""}
              <Box>
                {nonRecomendedComponents?.length
                  ? nonRecomendedComponents.map((component: any) => (
                      <Flex
                        key={
                          currentProductTypeComponentClass[
                            component.componentClass
                          ]?.name
                        }
                        className="items"
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        <StatusBubble
                          status={getFeatureStatus(component?.status)}
                        />
                        <Text>
                          {
                            currentProductTypeComponentClass[
                              component.componentClass
                            ]?.name
                          }
                        </Text>
                      </Flex>
                    ))
                  : ""}
              </Box>
            </Flex>
            <Divider className="component-divider" orientation="horizontal" />
            <Text as={"h4"} className="content-sub-heading-text">
              Additional product attributes
            </Text>
            {DEFAULT_ADDITIONAL_PRODUCT_ATTRIBUTES.filter((attribute) =>
              getCompleteAttributeList(productDetails?.ifxAcctType ?? "")?.some(
                (item) => item?.componentClass === attribute?.componentClass
              )
            ).map((product) => (
              <Flex
                className="items"
                alignItems={"center"}
                justifyContent={"flex-start"}
                key={product.name}
              >
                <StatusBubble
                  status={
                    productWorkflowData?.find(
                      (workFlowItem) =>
                        workFlowItem.stage === product.stageStatus
                    )?.status ?? DASHBOARD_ACCORDION_STATUS.completed
                  }
                />
                <Text>{product.name}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        {/* Footer */}
      </AppBodyGridWithHeader>
      <AppPortal domId="#appFooter">
        <Box className="configured-product-footer">
          <Button
            onClick={() => {
              router.back();
            }}
            mr="1.5rem"
            className="app-btn-reg-secondary-transparent"
          >
            Back
          </Button>
          {productDetails?.status === DASHBOARD_ACCORDION_STATUS.completed ? (
            <Button
              className="app-btn-inverse-secondary"
              onClick={() => {
                router.push(
                  `${ROUTE_PATH["PRODUCT_SUMMARY_FULLPATH"]}?productName=${currProductId}`
                );
              }}
              size="md"
            >
              Proceed to product summary
            </Button>
          ) : (
            <Button
              className="app-btn-inverse-secondary"
              onClick={navigateToNextComponent}
              size="md"
            >
              Continue
            </Button>
          )}
        </Box>
      </AppPortal>
    </>
  );
};

export default ConfigInterstitialScreen;
