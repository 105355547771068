import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
} from "@/components/ChakraUiManager";
import "./ProductTitle.scss";
import { BannerNotification, WrappedLink } from "@/components/common";

type ProductTitleProp = {
  title: string | undefined;
  subTitle?: string;
  link?: string;
  titleAbove?: boolean;
  align?: "left" | "off-left";
  buttonTitle?: string;
};

const ProductTitle = (props: ProductTitleProp) => {
  const {
    title,
    subTitle,
    link,
    titleAbove = false,
    align,
    buttonTitle,
  } = props;
  return (
    <Box className={"header-element"}>
      <Box
        className="header-back-ground-image"
        marginLeft={align === "left" ? -12 : 0}
      >
        <Flex
          className={`top-product-container`}
          py={align === "off-left" ? 12 : 8}
        >
          {titleAbove ? (
            <Box flexBasis="80%">
              <Heading className="top-product-title">{title}</Heading>
              {subTitle && (
                <Text className="top-product-sub-title-lower">{subTitle}</Text>
              )}
            </Box>
          ) : (
            <Box flexBasis="80%" sx={{ pl: align === "off-left" ? 80 : 0 }}>
              {subTitle && (
                <Text className={"top-product-sub-title"}>{subTitle}</Text>
              )}
              <Heading className="top-product-title">{title}</Heading>
            </Box>
          )}

          <Spacer />
          {link ? (
            <Box className="header-product-button">
              <WrappedLink href={link}>
                <Button className="app-btn-reg-secondary-transparent">
                  {buttonTitle}
                </Button>
              </WrappedLink>
            </Box>
          ) : (
            ""
          )}
        </Flex>
      </Box>
      <BannerNotification />
    </Box>
  );
};
export default ProductTitle;
