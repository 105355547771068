"use client";
import {
  Box,
  Stack,
  Heading,
  Text,
  Breadcrumb,
  BreadcrumbItem,
} from "@/components/ChakraUiManager";
import "./OverviewHeader.scss";
import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";

type Props = {
  documentTitle: string;
  overview?: string;
  breadcrumbs: string[];
};

export default function OverviewHeader(props: Props) {
  const { documentTitle, overview, breadcrumbs: breadcrumb } = props;

  function slugify(text: string) {
    return text.toLowerCase().replace(/\s+/g, "-");
  }
  return (
    <Box className="overview-header">
      <Stack className="text-stack">
        {breadcrumb?.length > 0 && (
          <Breadcrumb separator="|" className="breadcrumb">
            {breadcrumb.map((data, key) => {
              const currentUrl = window.location.href;
              let url;

              if (currentUrl.includes("api")) {
                if (data.toUpperCase() === "API REFERENCES HOME") {
                  url = "/apis";
                } else {
                  return (
                    <BreadcrumbItem key={key}>
                      <Text className="doc-upper-text">
                        {data.toUpperCase()}
                      </Text>
                    </BreadcrumbItem>
                  );
                }
              } else {
                if (data.toUpperCase() === "DOCS") {
                  url = "/docs";
                } else {
                  url = `/docs/document-detail/document/${slugify(data)}`;
                }
              }
              return (
                <BreadcrumbItem key={key}>
                  <Text className="doc-upper-text">
                    <a href={url} className="breadcrumb-link">
                      {data.toUpperCase()}
                    </a>
                  </Text>
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
        )}
        <Heading className="headline" as="h1">
          {documentTitle}
        </Heading>
        {overview && <Text className="subheadline">{overview}</Text>}
      </Stack>
    </Box>
  );
}
