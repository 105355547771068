"use client";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@/components/ChakraUiManager";
import { ProductTitle } from "@/components/common";
import { ROUTE_PATH } from "@/route-config/route-path";
import Style from "./ProductReview.module.scss";
import TestingRead from "./testing-readiness-tab/TestingRead";
import ApiCall from "./api-calls-tab/ApiCallTab";
import ProductSummary from "./product-config-tab/ProductSummary";
import { useEffect, useState } from "react";
import { getTestingReadData } from "@/prismic-api/prismic-api-service";
import {
  ProductSummaryTestingDocumentDataItemsItem,
  Simplify,
} from "../../../../prismicio-types";
import {
  ContentRelationshipField,
  GroupField,
  KeyTextField,
  RichTextField,
} from "@prismicio/client";
import { useSelector } from "react-redux";
import { getAppPrismicClient } from "@/store";
import AppBodyGridWithHeader from "@/components/layout/AppBodyGridWithHeader/AppBodyGridWithHeader";

export interface TestingProps {
  heading: KeyTextField;
  description: KeyTextField;
  items: GroupField<Simplify<ProductSummaryTestingDocumentDataItemsItem>>;
}
export interface ApiCallsProps {
  heading: KeyTextField;
  description: RichTextField;
  retailDeposit: ContentRelationshipField<"product_summary_api">;
  commercialDeposit: ContentRelationshipField<"product_summary_api">;
  retailLoan: ContentRelationshipField<"product_summary_api">;
  commercialLoan: ContentRelationshipField<"product_summary_api">;
}

const ProductReview = () => {
  const prismicClient = useSelector(getAppPrismicClient);

  const [tabDetails, setTabDetails] = useState<{
    testing: TestingProps | null;
    apiCalls: ApiCallsProps | null;
  } | null>(null);

  async function getTesingReadDetails() {
    const data = await getTestingReadData(prismicClient);
    await Promise.all([data]).then((values) => {
      setTabDetails(values[0]);
    });
  }

  useEffect(() => {
    if (!tabDetails) {
      getTesingReadDetails();
    }
  }, []);

  return (
    <AppBodyGridWithHeader>
      <ProductTitle
        title={"Product Summary"}
        link={`${ROUTE_PATH["PRODUCT_LAUNCHPAD"]}`}
        buttonTitle={"Product Launchpad"}
        titleAbove
        align="off-left"
      />
      <Box mb={32}>
        {/* Three tab that is present on review page will render here */}
        <Tabs className={Style["product-review-tabs"]}>
          <TabList>
            <Tab>Product configurations</Tab>
            <Tab>Testing readiness</Tab>
            <Tab>API Calls</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ProductSummary />
            </TabPanel>
            <TabPanel>
              <TestingRead data={tabDetails?.testing} />
            </TabPanel>
            <TabPanel>
              <ApiCall data={tabDetails?.apiCalls} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </AppBodyGridWithHeader>
  );
};
export default ProductReview;
