"use client";

import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import {
  createEnvironmentRequest,
  getConsolePermissions,
} from "@/api-config/api-service";
import { UserContext } from "@/app/providers";
import { getEnvStatus, getOrganizations } from "@/store";
import {
  BUSINESS_TYPES,
  EXPLORE_FINXACT_STEPS_NAME,
  FINANCIAL_PRODUCTS,
  SANDBOX_OBJECTIVES,
  TICKET_REQUESTS,
} from "@/utils/constants";
import { Box, Divider, Text, useToast } from "@chakra-ui/react";
import { MutableRefObject, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import countryList from "react-select-country-list";
import { AppFormLabel, AppModal, InputText, SelectDropdown } from "..";
import "./FinxactFeedbackFormModal.scss";

interface FormData {
  businessType: string;
  location: string;
  products: string[];
  objective: string;
  objectiveSelection: string;
  message: string;
}

interface FinxactFeedbackFormModalProp {
  requestType: string;
  modalRef: MutableRefObject<any>;
  title: string | "";
}

const EMBARGOED_COUNTRIES = [
  "Cuba",
  "Iran, Islamic Republic of",
  "Korea, Democratic People's Republic of",
  "Syrian Arab Republic",
  "Russian Federation",
  "Belarus",
];

interface CountryData {
  label: string;
  value: string;
}

function FinxactFeedbackFormModal(props: FinxactFeedbackFormModalProp) {
  const { requestType, modalRef, title } = props;
  const toast = useToast();
  const toastId = "request-finxact-modal";
  const envData = useSelector(getEnvStatus);

  const {
    user: { firstName, lastName, email, organization },
  } = useContext(UserContext);
  const allOrgs = useSelector(getOrganizations);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [formData, setFormData] = useState<FormData>({
    businessType: "",
    location: "",
    products: [],
    objective: "",
    objectiveSelection: "",
    message: "",
  });

  const countries = useMemo(() => {
    return countryList()
      .getData()
      .map((country: CountryData) => ({
        value: country.label,
        label: country.label,
      }))
      .filter((country) => !EMBARGOED_COUNTRIES.includes(country.value));
  }, []);

  const isFormValid = () => {
    const { businessType, location, products, objective } = formData;
    return (
      businessType && location && products.length > 0 && objective.trim() !== ""
    );
  };

  async function onModalInputSave() {
    setSubmitting(true);

    const messagePayload = {
      BusinessType: formData.businessType,
      Location: formData.location,
      InterestedProducts: formData.products,
      Objective:
        formData.objectiveSelection === "Other"
          ? formData.objective
          : formData.objectiveSelection,
      Message: formData.message,
    };

    const payload = {
      type:
        requestType === EXPLORE_FINXACT_STEPS_NAME.sandboxOverview
          ? TICKET_REQUESTS.sandbox
          : TICKET_REQUESTS.environment,
      message: messagePayload,
    };

    try {
      const response = await createEnvironmentRequest({
        body: payload,
        toastOptions: {
          toast,
          toastId,
          successMessage: "Request sent successfully",
        },
      });

      const api = new ApiClient({ baseUrl: getConsolePermissions() });
      const [data] = await Promise.all([
        api.get(`${API_ROUTE_CONFIGURATION.requests}`),
      ]);
    } catch (error) {
      console.error("Error creating environment request:", error);

      toast({
        title: "Error",
        description:
          "There was an issue submitting your request. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      modalRef?.current.closeModal();
      setFormData({
        businessType: "",
        location: "",
        products: [],
        objective: "",
        objectiveSelection: "",
        message: "",
      });
      setSubmitting(false);
    }
  }

  function onModalInputCancel() {
    // Reset modal input value
    setFormData({
      businessType: "",
      location: "",
      products: [],
      objective: "",
      objectiveSelection: "",
      message: "",
    });

    modalRef?.current.closeModal();
  }

  return (
    <AppModal
      customClass="app-modal-selectors chakra-modal__body"
      ref={modalRef}
      modalTitle={title}
      primaryBtnProp={{
        name: "Submit request",
        btnClassName: "app-btn-inverse-secondary",
      }}
      primaryBtnDisabled={!isFormValid() || submitting}
      primaryBtnSelect={onModalInputSave}
      secondaryBtnSelect={onModalInputCancel}
      secondaryBtnProp={{
        name: "Cancel",
        btnClassName: "app-btn-link",
        style: { marginRight: "1rem" },
      }}
    >
      <Box className="app-form-field-container-small">
        <Text>
          Let’s chat. We’d love to share more about our value offering and
          answer any questions you may have.{" "}
        </Text>
      </Box>

      <Box mb={12.8}>
        <AppFormLabel
          labelName="What type of business is your organization?"
          isRequired={true}
        />
        <SelectDropdown
          dropdownList={BUSINESS_TYPES.map((type: string) => ({
            value: type,
            label: type,
          }))}
          value={formData.businessType}
          onChange={(value) =>
            setFormData((prev) => ({
              ...prev,
              businessType: value,
            }))
          }
          placeholder="Select business type"
        />
      </Box>

      <Box mb={12.8}>
        <AppFormLabel
          labelName="Where is your organization located?"
          isRequired={true}
        />
        <SelectDropdown
          dropdownList={countries}
          value={formData.location}
          onChange={(value) =>
            setFormData((prev) => ({
              ...prev,
              location: value,
            }))
          }
          placeholder="Select country"
        />
      </Box>

      <Box mb={12.8}>
        <AppFormLabel
          labelName="What financial products are you interested in?"
          isRequired={true}
        />
        <SelectDropdown
          dropdownList={FINANCIAL_PRODUCTS.map((product: string) => ({
            value: product,
            label: product,
          }))}
          value={formData.products}
          onChange={(values) =>
            setFormData((prev) => ({
              ...prev,
              products: values,
            }))
          }
          placeholder="Select products"
          isMulti
        />
      </Box>

      <Box mb={12.8}>
        <AppFormLabel
          labelName="What is your objective for Sandbox?"
          isRequired={true}
        />
        <SelectDropdown
          dropdownList={SANDBOX_OBJECTIVES.map((objective: string) => ({
            value: objective,
            label: objective,
          }))}
          value={formData.objectiveSelection}
          onChange={(value) =>
            setFormData((prev) => ({
              ...prev,
              objectiveSelection: value,
              objective: value === "Other" ? "" : value,
            }))
          }
          placeholder="Select objective"
        />
      </Box>

      {formData.objectiveSelection === "Other" && (
        <Box mb={12.8}>
          <AppFormLabel
            labelName="Please specify your objective"
            isRequired={true}
          />
          <InputText
            value={formData.objective}
            onChange={(value) =>
              setFormData((prev) => ({ ...prev, objective: value }))
            }
            placeholder="Enter your objective"
          />
        </Box>
      )}

      <Box mb={12.8}>
        <AppFormLabel labelName="Add a message (Optional)" isRequired={false} />
        <InputText
          value={formData.message}
          onChange={(value) =>
            setFormData((prev) => ({ ...prev, message: value }))
          }
          placeholder="Enter any additional information"
        />
      </Box>

      <Divider />
    </AppModal>
  );
}

export default FinxactFeedbackFormModal;
