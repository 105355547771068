import { Box, Flex } from "@/components/ChakraUiManager";
import "./TopBannerContainer.scss";

type TopBannerContainerProp = {
  children: React.ReactNode;
  backgroundImage?: boolean;
};

export default function TopBannerContainer(props: TopBannerContainerProp) {
  const { children, backgroundImage = false } = props;
  return (
    <Box className={backgroundImage ? "header-back-ground-image" : ""}>
      <Flex alignItems={"center"} className="hero-banner-container">
        <Box w={"100%"}>{children}</Box>
      </Flex>
    </Box>
  );
}
