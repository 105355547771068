// URL imports for svg (https://react-svgr.com/docs/next/#usage) so it works with next/image
import upArrow from "../assets/images/arrow-up.svg?url";
import chevRightIcon from "../assets/images/chev-right.svg?url";
import chewRightWhiteIcon from "../assets/images/right-chev-white.svg?url";
import activeInterstial from "./images/active-interstial.svg?url";
import arrowForwardIcon from "./images/arrow-forward.svg?url";
import academyBannerImage from "./images/banner-image.svg?url";
import binIcon from "./images/bin.svg?url";
import blueComplete from "./images/blue-complete.svg?url";
import checkWithCirle from "./images/check-with-circle.svg?url";
import checkWithCirle2 from "./images/check-with-circle2.svg?url";
import checkIcon from "./images/check.svg?url";
import checklistIcon from "./images/checklist.svg?url";
import blackChewDownIcon from "./images/chew-down-black.svg?url";
import closeIcon from "./images/close-icon.svg?url";
import completeInterstial from "./images/complete-interstial.svg?url";
import completeInterstialTopIcon from "./images/completed-interstial-top-icon.svg?url";
import accordionDeleteIcon from "./images/delete-icon.svg?url";
import documentCenterBannerImage from "./images/doc-banner.svg?url";
import draggableIcon from "./images/dragable-icon.svg?url";
import editIcon from "./images/edit-icon.png";
import errorStatusImg from "./images/error-status.svg?url";
import expandDownIcon from "./images/expand-down.svg?url";
import expandUpIcon from "./images/expand-up.svg?url";
import expandIcon from "./images/expand.svg?url";
import deleteHolidayIcon from "./images/fasticon-delete.svg?url";
import favicon from "./images/favicon.ico";
import finxactDarkModeIcon from "./images/finxact-dark-mode.svg?url";
import mediaImgLandScape from "./images/finxact-icon-card-landscape.png";
import mediaImg from "./images/finxact-icon-card.png";
import finxactLogoWhite from "./images/finxact-logo-white.svg?url";
import finxactOutlineTime from "./images/finxact-outline-time.svg?url";
import iconOutlineSurvey from "./images/Icons-Outline-Survey.svg?url";
import kebabIcon from "./images/kebab.svg?url";
import lockImage from "./images/lock-image.svg?url";
import lockIcon from "./images/lock.svg?url";
import myProductDefaultImg from "./images/my-product-default.png";
import overview from "./images/Overview.svg?url";
import redAlert from "./images/red-alert.svg?url";
import resourceIcon from "./images/resource.svg?url";
import rightArrow from "./images/right-arrow.svg?url";
import rightRotateImage from "./images/right-rotate-progress.svg?url";
import rightRotateYellow from "./images/right-rotate-yellow.svg?url";
import rightRotateProgress from "./images/rightRotateProgress.svg?url";
import rightRotate from "./images/rotate_right.svg?url";
import solidRightArrow from "./images/solidRightArrow.svg?url";
import trainingDocumentation from "./images/training-documentation.svg?url";
import warnningStatusImg from "./images/warning-status.svg?url";
import warningIcon from "./images/warning.svg?url";

import badgePathDefault from "./images/badge-path-default.png";
import bookmarkIcon from "./images/bookmark_border.png";
import bookmarkedIcon from "./images/bookmark_filled.png";
import certificationDefault from "./images/certification-default.png";
import clockIcon from "./images/clock.svg?url";
import apiIcon from "./images/code_blocks.svg?url";
import completedRadio from "./images/completedRadio.png";
import copyIcon from "./images/content_copy.svg?url";
import courseDefault from "./images/course-default.png";
import dashboardFoundationalSetupIcon from "./images/dashboard-foundational-setup-icon.svg?url";
import dashBoardJumpstartIcon from "./images/dashboard-jumpstart-icon.svg?url";
import dashboardLockIcon from "./images/dashboard-lock-icon.svg?url";
import dashboardProductConfigIcon from "./images/dashboard-product-config-icon.svg?url";
import dashboardStarIcon from "./images/dashboard-star-icon.svg?url";
import dashboardTestDeployIcon from "./images/dashboard-test-deploy-icon.svg?url";
import dashboardEnvUpgradeIcon from "./images/dashboard-upgrade-env-icon.svg?url";
import deleteIconDefault from "./images/delete-icon-white.svg?url";
import downloadIcon from "./images/download.svg?url";
import IconEdit from "./images/edit.svg?url";

import expandDownBlack from "./images/expand-down-black.svg?url";
import blackCheck from "./images/FAST ICON - check.svg?url";
import docCenterSideMenuChevRight from "./images/FAST ICON - chevron_right-doc-center.svg?url";
import docCenterSidemenuChevRightSelected from "./images/FAST ICON - chevron_right-selected.svg?url";
import docCenterSidemenuIconHome from "./images/FAST icon - home-doc-center.svg?url";
import fastBadgeIcon from "./images/fast-icon-badge.svg?url";
import fastCertificationIcon from "./images/fast-icon-certification.svg?url";
import fastCourseIcon from "./images/fast-icon-course.svg?url";
import fastShareIcon from "./images/fast-icon-share.svg?url";
import IconFeatureDetails from "./images/feature-details.svg?url";
import finxactOutlineTimDisabled from "./images/finxact-outline-time-disabled.svg?url";
import greenStatusDot from "./images/green-dot.svg?url";
import resourcesIcon from "./images/help.svg?url";
import iconOutlineDownChevron from "./images/Icon Outline Down Chevron.svg?url";
import exploreImage from "./images/Illustration.svg?url";
import InstitutionalConfigSlide2 from "./images/institutional-config-2.png";
import InstitutionalConfigSlide3 from "./images/institutional-config-3.png";
import InstitutionalConfigSlide4 from "./images/institutional-config-4.png";
import InstitutionalConfigSlide5 from "./images/institutional-config-5.svg?url";
import noCatalogFound from "./images/no-catalog-found.svg?url";
import notCompletedRadio from "./images/notCompletedRadio.png";
import ProductConfigSlide2 from "./images/product-config-1.svg?url";
import ProductConfigSlide3 from "./images/product-config-3.svg?url";
import ProductConfigSlide4 from "./images/product-config-4.png";
import ProductConfigSlide5 from "./images/product-config-5.svg?url";
import shareIconDoc from "./images/share-icon.svg?url";
import shareIcon from "./images/share_icon.png";
import smartChampionIcon from "./images/smart-champion.svg?url";
import stepperActive from "./images/stepperActive.svg?url";

// Teaser
import laptopProductLaunchpad from "./images/laptop-product-launchpad.gif";
import orangeBlocks from "./images/orange-blocks.svg?url";
import teaserConfigure from "./images/teaser-configure.svg?url";
import teaserDesign from "./images/teaser-design.svg?url";
import teaserExploreSandbox from "./images/teaser-explore-sandbox.svg?url";
import teaserHowItWorksBg from "./images/teaser-howItWorksBg.svg?url";
import teaserLaunch from "./images/teaser-launch.svg?url";
import teaserProductReview from "./images/teaser-product-review.png";
import teaserChecklist from "./images/teaser/checklist.svg?url";
import teaserEmbedFinanceEmpowerCustomers from "./images/teaser/embed-finance-empower-customers.svg?url";
import teaserEmbedFinanceFeaturesBenefits from "./images/teaser/embed-finance-features-benefits.svg?url";
import teaserEmbedFinanceValueForCustomers from "./images/teaser/embed-finance-value-for-customers.svg?url";
import teaserEmbedFinance from "./images/teaser/embed-finance.svg?url";
import teaserIphoneSavingsPockets1 from "./images/teaser/iphone-savings-pockets-1.svg?url";
import teaserIphoneSavingsPockets2 from "./images/teaser/iphone-savings-pockets-2.svg?url";
import teaserLightBulb from "./images/teaser/lightbulb.svg?url";
import teaserMonitorCode from "./images/teaser/monitor-code.svg?url";
import teaserMonitorDocument from "./images/teaser/monitor-document.svg?url";
import teaserMultiDevice from "./images/teaser/multi-device.svg?url";
import teaserOneAccount from "./images/teaser/one-account.svg?url";
import teaserPhoneOutlineSavingsPockets from "./images/teaser/phone-outline-savings-pockets.svg?url";

import GeneralLedgerConfig2 from "./images/generalLedger-config-2.svg?url";
import GeneralLedgerConfig3 from "./images/generalLedger-config-3.svg?url";
import GeneralLedgerConfig4 from "./images/generalLedger-config-4.svg?url";
import GeneralLedgerConfig5 from "./images/generalLedger-config-5.svg?url";
import triangularUpArrow from "./images/up-arrow.svg?url";

//Transaction codes
import TransactionCodeSlide3 from "./images/transaction-code-config-3.svg?url";
import TransactionCodeSlide4 from "./images/transaction-code-config-4.svg?url";
import TransactionCodeSlide5 from "./images/transaction-code-config-5.svg?url";
import TransactionCodeSlide1 from "./images/transaction-code-setup-1.svg?url";

import warningCloseIcon from "./images/close.svg?url";
import dotPatternWalkthrough from "./images/dot-pattern-walkthrough.svg?url";
import faAPILibraryWelcomeMessageImage from "./images/fa-icon-api-library-welcome-message.svg?url";
import sideMenuMultiTieredBackIcon from "./images/FAST ICON - chevron_left.svg?url";
import sideMenuCollapseIcon from "./images/FAST ICON - expand_circle_left.svg?url";
import sideMenuExpandIcon from "./images/FAST ICON - expand_circle_right.svg?url";
import upDownArrow from "./images/up-down-arror.svg?url";

import closeIconMobile from "./images/close-mobile.svg?url";
import hamburger from "./images/hamburger.svg?url";

import IconCalendar from "./images/calendar.svg?url";
import CustomerRelationshipTypeConfig from "./images/customer-relationship-type-config.svg?url";
import IconForward from "./images/icon-forward.svg?url";
import IconOrgEnvSelected from "./images/icon-org-env-selected.svg?url";
import IconEnvOrgDropDownButtonExpandLess from "./images/IconEnvOrgDropDownButtonExpandLess.svg?url";
import IconEnvOrgDropDownButtonExpandMore from "./images/IconEnvOrgDropDownButtonExpandMore.svg?url";
import IconEnvOrgDropDownSearch from "./images/IconEnvOrgDropDownSearch.svg?url";
import IconEnvOrgExpandLess from "./images/IconEnvOrgExpandLess.svg?url";
import IconEnvOrgExpandMore from "./images/IconEnvOrgExpandMore.svg?url";
import IconEnvOrgFav from "./images/IconEnvOrgFav.svg?url";
import IconEnvOrgNotFav from "./images/IconEnvOrgNotFav.svg?url";
import iconEnvOrgSelectionDivider from "./images/IconEnvOrgSelectionDivider.svg?url";
import IconsOutlinePlay from "./images/Icons-Outline-Play.svg?url";
import manageAccountIcon from "./images/manage_accounts.svg?url";
import spanner from "./images/spanner.png";
import vectorSelected from "./images/vector-selected.svg?url";
import vector from "./images/vector.svg?url";

import completedIcon from "public/assets/images/green-tick-outline.svg?url";
import inProgressIcon from "public/assets/images/in-progress-icon.svg?url";
import inActiveIcon from "public/assets/images/white-circle-icon.svg?url";
import completionImage from "./images/completion-image.svg?url";

import AccordionExpandMore from "./images/AccordionExpandMore.svg?url";
import addIconDark from "./images/add-icon-dark.svg?url";
import arrowForwardBlack from "./images/arrow-forward-black.svg?url";
import arrowForwardBlue from "./images/arrow-forward-blue.svg?url";
import enrollmentChecklist1 from "./images/enrollmment-checklist-1.svg?url";
import enrollmentChecklist2 from "./images/enrollmment-checklist-2.svg?url";
import enrollmentChecklist3 from "./images/enrollmment-checklist-3.svg?url";
import enrollmentChecklist4 from "./images/enrollmment-checklist-4.svg?url";
import FastInfoCallout from "./images/FAST - Info Callout.svg?url";
import FastIconExpandLessBlue from "./images/FAST ICON - expand_less_blue.svg?url";
import FastIconExpandMore from "./images/FAST ICON - expand_more.svg?url";
import infoIconDark from "./images/info-icon-dark.svg?url";
import linkedinIcon from "./images/linkedin.svg?url";
import ProductConfigSlider2 from "./images/product-config-slide-2.jpg";
import setupCompletionIcon from "./images/profile-setup-completion.svg?url";
import setupFailedIcon from "./images/profile-setup-failed.svg?url";
import QuickReference from "./images/quick_reference.svg?url";
export {
  AccordionExpandMore,
  CustomerRelationshipTypeConfig,
  FastIconExpandLessBlue,
  FastIconExpandMore,
  FastInfoCallout,
  GeneralLedgerConfig2,
  GeneralLedgerConfig3,
  GeneralLedgerConfig4,
  GeneralLedgerConfig5,
  IconCalendar,
  IconEdit,
  IconEnvOrgDropDownButtonExpandLess,
  IconEnvOrgDropDownButtonExpandMore,
  IconEnvOrgDropDownSearch,
  IconEnvOrgExpandLess,
  IconEnvOrgExpandMore,
  IconEnvOrgFav,
  IconEnvOrgNotFav,
  IconFeatureDetails,
  IconForward,
  IconOrgEnvSelected,
  IconsOutlinePlay,
  InstitutionalConfigSlide2,
  InstitutionalConfigSlide3,
  InstitutionalConfigSlide4,
  InstitutionalConfigSlide5,
  ProductConfigSlide2,
  ProductConfigSlide3,
  ProductConfigSlide4,
  ProductConfigSlide5,
  ProductConfigSlider2,
  QuickReference,
  //Transaction codes
  TransactionCodeSlide1,
  TransactionCodeSlide3,
  TransactionCodeSlide4,
  TransactionCodeSlide5,
  academyBannerImage,
  accordionDeleteIcon,
  activeInterstial,
  addIconDark,
  apiIcon,
  arrowForwardBlack,
  arrowForwardBlue,
  arrowForwardIcon,
  badgePathDefault,
  binIcon,
  blackCheck,
  blackChewDownIcon,
  blueComplete,
  bookmarkIcon,
  bookmarkedIcon,
  certificationDefault,
  checkIcon,
  checkWithCirle,
  checkWithCirle2,
  checklistIcon,
  chevRightIcon,
  chewRightWhiteIcon,
  clockIcon,
  closeIcon,
  closeIconMobile,
  completeInterstial,
  completeInterstialTopIcon,
  completedIcon,
  completedRadio,
  completionImage,
  copyIcon,
  courseDefault,
  dashBoardJumpstartIcon,
  dashboardEnvUpgradeIcon,
  dashboardFoundationalSetupIcon,
  dashboardLockIcon,
  dashboardProductConfigIcon,
  dashboardStarIcon,
  dashboardTestDeployIcon,
  deleteHolidayIcon,
  deleteIconDefault,
  docCenterSideMenuChevRight,
  docCenterSidemenuChevRightSelected,
  docCenterSidemenuIconHome,
  documentCenterBannerImage,
  dotPatternWalkthrough,
  downloadIcon,
  draggableIcon,
  editIcon,
  enrollmentChecklist1,
  enrollmentChecklist2,
  enrollmentChecklist3,
  enrollmentChecklist4,
  errorStatusImg,
  expandDownBlack,
  expandDownIcon,
  expandIcon,
  expandUpIcon,
  exploreImage,
  faAPILibraryWelcomeMessageImage,
  fastBadgeIcon,
  fastCertificationIcon,
  fastCourseIcon,
  fastShareIcon,
  favicon,
  finxactDarkModeIcon,
  finxactLogoWhite,
  finxactOutlineTimDisabled,
  finxactOutlineTime,
  greenStatusDot,
  hamburger,
  iconEnvOrgSelectionDivider,
  iconOutlineDownChevron,
  iconOutlineSurvey,
  inActiveIcon,
  inProgressIcon,
  infoIconDark,
  kebabIcon,
  laptopProductLaunchpad,
  linkedinIcon,
  lockIcon,
  lockImage,
  manageAccountIcon,
  mediaImg,
  mediaImgLandScape,
  myProductDefaultImg,
  noCatalogFound,
  notCompletedRadio,
  orangeBlocks,
  overview,
  redAlert,
  resourceIcon,
  resourcesIcon,
  rightArrow,
  rightRotate,
  rightRotateImage,
  rightRotateProgress,
  rightRotateYellow,
  setupCompletionIcon,
  setupFailedIcon,
  shareIcon,
  shareIconDoc,
  sideMenuCollapseIcon,
  sideMenuExpandIcon,
  sideMenuMultiTieredBackIcon,
  smartChampionIcon,
  solidRightArrow,
  spanner,
  stepperActive,
  teaserChecklist,
  teaserConfigure,
  teaserDesign,
  teaserEmbedFinance,
  teaserEmbedFinanceEmpowerCustomers,
  teaserEmbedFinanceFeaturesBenefits,
  teaserEmbedFinanceValueForCustomers,
  teaserExploreSandbox,
  teaserHowItWorksBg,
  teaserIphoneSavingsPockets1,
  teaserIphoneSavingsPockets2,
  teaserLaunch,
  teaserLightBulb,
  teaserMonitorCode,
  teaserMonitorDocument,
  teaserMultiDevice,
  teaserOneAccount,
  teaserPhoneOutlineSavingsPockets,
  teaserProductReview,
  trainingDocumentation,
  triangularUpArrow,
  upArrow,
  upDownArrow,
  vector,
  vectorSelected,
  warningCloseIcon,
  warningIcon,
  warnningStatusImg,
};

// SVG React Component Exports (@svgr/webpack)
import SVGArrowForwardIcon from "./images/arrow-forward.svg";
import SVGAcademyBannerImage from "./images/banner-image.svg";
import SVGDocumentCenterBannerImage from "./images/doc-banner.svg";
import SVGApiIcon from "./images/enrollmment-checklist-3.svg";
import SVGFaAPILibraryWelcomeMessageImage from "./images/fa-icon-api-library-welcome-message.svg";
import SVGResourceIcon from "./images/resource.svg";

export {
  SVGAcademyBannerImage,
  SVGApiIcon,
  SVGArrowForwardIcon,
  SVGDocumentCenterBannerImage,
  SVGFaAPILibraryWelcomeMessageImage,
  SVGResourceIcon,
};
