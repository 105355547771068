"use client";

import {
  Box,
  Button,
  ChevronRightIcon,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  TableContainer,
  Text,
  Image as ChakraImg,
} from "@/components/ChakraUiManager";

import Image from "next/image";
import "./RecentSection.scss";
import {
  errorStatusImg,
  kebabIcon,
  myProductDefaultImg,
  warnningStatusImg,
} from "public/assets";
import { Badges, CustomTable } from "@/components/common";
import { RECENT_PRODUCT_LIST_TABLE_COLUMN_REF } from "@/data/product-catalog-data";
import Link from "next/link";
import { ROUTE_PATH } from "@/route-config/route-path";

import { useCallback, useEffect, useState } from "react";

import {
  DASHBOARD_ACCORDION_STATUS,
  ENTITLEMENT_STATUS,
  EntitlementPageType,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_PRODUCT_TYPE_VAL,
} from "@/utils/constants";
import {
  ProductType,
  ProductWorkflowModelType,
  filterProductsWithWorkflowStatus,
  setQuickStartFlagInStorage,
} from "@/components/product-management";
import { useRouter } from "next/navigation";
import { RecentHeaderProp } from "../model";
import { getAllWorkflowGeneric } from "@/api-config/api-service";
import { getCurrentProductFormLinkToNavigate } from "@/components/product-management/product-summary/product-summary-config";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { ENTITLEMENT_MESSAGE } from "@/components/data/error-data";
import { checkEntitlement, isFastProduct } from "@/utils/common";

type MyProductsProp = {
  recentHeaders: RecentHeaderProp;
  productList: ProductType[];
  showProductTable: boolean;
};

export const myProductsColumnDef = RECENT_PRODUCT_LIST_TABLE_COLUMN_REF;

function RecentSection(prop: MyProductsProp) {
  const router = useRouter();
  const entitlement = useSelector(getEntitlement);
  const envIsDisable = useSelector(getEnvIsDisable);

  const [workflowProductList, setWorkflowProductList] = useState<
    ProductWorkflowModelType[]
  >([]);

  const { recentHeaders, productList = [], showProductTable } = prop;
  const [filteredProductList, setFilteredProductList] = useState<ProductType[]>(
    []
  );

  const renderDataCell = useCallback(
    (value: any, key: string, row: ProductType) => {
      switch (key) {
        case "name":
          return constructNameCell(row);
        case "status":
          return (
            <>
              {value ? (
                <Badges
                  label={getBadgeDetails(value, true)}
                  type={getBadgeDetails(value)}
                />
              ) : (
                <Box>-</Box>
              )}
            </>
          );
        case "description":
          return (
            <Box className="my-product-table-description">{value || ""}</Box>
          );
        case "action":
          return constructActionCell(row);
        default:
          return value;
      }
    },
    [filteredProductList, workflowProductList]
  );

  const constructActionCell = (product: ProductType) => {
    const prodType = product.ifxAcctType
      ? IFX_ACCT_PRODUCT_TYPE[product.ifxAcctType]
      : "";
    if (
      (prodType === IFX_PRODUCT_TYPE_VAL.deposit &&
        entitlement.pc_dep === ENTITLEMENT_STATUS.noAccess) ||
      (prodType === IFX_PRODUCT_TYPE_VAL.loan &&
        entitlement.pc_loan === ENTITLEMENT_STATUS.noAccess)
    )
      return null;

    return (
      <Box className="constructed-action-cell">
        {product.status?.toLowerCase() ===
        DASHBOARD_ACCORDION_STATUS.inProgress ? (
          <Button
            className="app-btn-reg-secondary"
            onClick={() =>
              router.push(
                getCurrentProductFormLinkToNavigate(
                  product,
                  workflowProductList?.filter(
                    (item) => item?.modelKey === product?.name
                  )
                )
              )
            }
          >
            {checkEntitlement(
              entitlement,
              prodType === IFX_PRODUCT_TYPE_VAL.loan
                ? EntitlementPageType.pc_loan
                : EntitlementPageType.pc_dep,
              [ENTITLEMENT_STATUS.readOnly]
            ) || envIsDisable
              ? "View"
              : "Resume"}
            <Text ml={2} as="span">
              {">"}
            </Text>
          </Button>
        ) : (
          <Menu>
            <MenuButton
              border={"none"}
              className="kebab-btn"
              as={IconButton}
              aria-label="Options"
              icon={<Image src={kebabIcon} alt="image" />}
              variant="outline"
            />
            <MenuList className="menu-list-container">
              <MenuItem
                onClick={() => {
                  if (
                    product.ifxAcctType &&
                    isFastProduct(product.ifxAcctType)
                  ) {
                    navigateToProductSummary(product, "edit");
                  } else {
                    window.location.href = `${ROUTE_PATH.LEGACY_EDIT_PRODUCT}/${product.name}/?returnTo=${encodeURIComponent(ROUTE_PATH.PRODUCT_LAUNCHPAD)}`;
                  }
                }}
              >
                {checkEntitlement(
                  entitlement,
                  prodType === IFX_PRODUCT_TYPE_VAL.loan
                    ? EntitlementPageType.pc_loan
                    : EntitlementPageType.pc_dep,
                  [ENTITLEMENT_STATUS.readOnly]
                ) || envIsDisable
                  ? "View"
                  : "Edit"}
              </MenuItem>
              {!checkEntitlement(
                entitlement,
                prodType === IFX_PRODUCT_TYPE_VAL.loan
                  ? EntitlementPageType.pc_loan
                  : EntitlementPageType.pc_dep,
                [ENTITLEMENT_STATUS.allAccess]
              ) || envIsDisable ? (
                ""
              ) : (
                <MenuItem
                  onClick={() => {
                    navigateToProductSummary(product, "copy");
                  }}
                >
                  Copy
                </MenuItem>
              )}
              <MenuItem>
                <a
                  href={`${ROUTE_PATH["EXPLORE_PRODUCT_IN_LEGACY"]}/${product.name}`}
                  target="_blank"
                >
                  Explore
                </a>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Box>
    );
  };

  const constructNameCell = (product: any) => {
    return (
      <Flex className="constructed-name-cell">
        {product.warningCount ? (
          <Box width={"15%"}>
            <Image src={warnningStatusImg} alt="warning" />
          </Box>
        ) : (
          ""
        )}
        {product.errorCount ? (
          <Box width={"15%"}>
            <Image src={errorStatusImg} alt="error" />{" "}
          </Box>
        ) : (
          ""
        )}
        <Box
          width={`${product.errorCount || product.warningCount ? "85%" : "100%"}`}
        >
          <Text
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {product.name || ""}
          </Text>
          {product.warningCount ? (
            <Text className="warning-count">2 non-critical warning</Text>
          ) : (
            ""
          )}
          {product.errorCount ? (
            <Text className="error-count">1 cirtical error</Text>
          ) : (
            ""
          )}
        </Box>
      </Flex>
    );
  };

  function getBadgeDetails(status: string, needValue = false) {
    const statusLowerCase = status?.toLowerCase() || "";
    if (statusLowerCase === "completed") {
      return needValue ? status : "success";
    } else if (
      statusLowerCase === "in_progress" ||
      statusLowerCase === "configuration warning"
    ) {
      return needValue ? "in progress" : "warning";
    } else if (statusLowerCase === "configuration error") {
      return needValue ? status : "error";
    } else {
      return "";
    }
  }

  function navigateToProductSummary(product: ProductType, type: string) {
    let isQuickStartFlow = false;
    if (type === "copy") {
      isQuickStartFlow = true;
    }
    setQuickStartFlagInStorage(isQuickStartFlow);
    router.push(
      `${ROUTE_PATH["PRODUCT_SUMMARY_FULLPATH"]}${ROUTE_PATH["PRODUCT_NAME_QUERY"]}${product.name}`
    );
  }

  async function initMyProducts() {
    if (productList?.length) {
      await getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.product).then(
        (workflowProducts) => {
          setWorkflowProductList(workflowProducts as any);
        }
      );
      const filteredProduct = filterProductsWithWorkflowStatus(productList);
      filteredProduct.sort(
        (a, b) =>
          new Date(b._uDtm ?? "").getTime() - new Date(a._uDtm ?? "").getTime()
      );
      setFilteredProductList(filteredProduct.slice(0, 5));
    }
  }

  useEffect(() => {
    initMyProducts();
  }, [productList]);

  return (
    <Box className="recent-products">
      <Box className="recent-product-header" p={12.8}>
        <Heading className="recent-product-title">
          {recentHeaders?.recentTitle}
        </Heading>
        {filteredProductList?.length &&
        showProductTable &&
        entitlement.pc_dep !== ENTITLEMENT_STATUS.noAccess &&
        entitlement.pc_loan !== ENTITLEMENT_STATUS.noAccess ? (
          <Text className="recent-product-desc">
            {recentHeaders?.recentSubTitle}
          </Text>
        ) : (
          ""
        )}
      </Box>

      {!filteredProductList?.length ||
      !showProductTable ||
      (entitlement.pc_dep === ENTITLEMENT_STATUS.noAccess &&
        entitlement.pc_loan === ENTITLEMENT_STATUS.noAccess) ? (
        <Flex className="recent-products-container">
          <Box className="recent-products-default-img">
            <ChakraImg src={myProductDefaultImg.src} alt="recent product" />
          </Box>
          <Text className="recent-products-text">
            {entitlement.pc_dep === ENTITLEMENT_STATUS.noAccess &&
            entitlement.pc_loan === ENTITLEMENT_STATUS.noAccess
              ? ENTITLEMENT_MESSAGE.noAccess
              : `You don’t have any products configured yet. Follow and complete all
            the pre-requisite steps in your Finxact journey above to begin
            configuration.`}
          </Text>
        </Flex>
      ) : (
        <Box pl={12.8} pr={12.8}>
          <TableContainer className="recent-products-table">
            <CustomTable
              data={filteredProductList}
              columns={myProductsColumnDef}
              renderDataCell={renderDataCell}
              className="recent-product-table"
              isPagination={true}
            />
          </TableContainer>
          <Link href={`${ROUTE_PATH["PRODUCT_LAUNCHPAD"]}`}>
            <Button className="app-btn-link product-link">
              View all your products
              <ChevronRightIcon w={10} h={10} />
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
}

export default RecentSection;
