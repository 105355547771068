const QUERY_PARAM_KEY = {
  PRODUCT_NAME_KEY: `productName`,
  FIN_ORG_ID: `finOrgId`,
  FIN_ORG_CORRS_INDEX: `corrsIndex`,
  ENTITY_ID: `entityId`,
  TC_ENTRY_INDEX: "entryIndex",
  DOC_TYPE: "docType",
  DOC_ID: "docId",
  IFX_TYPE: "ifxType",
  PROD_SUB_TYPE: "prodSubType",
  ACCT_TYPE_REL_INDEX: "relIndex",
  ACTION: "action",
  FLOW_NAME: "flowName",
  CONFIG_ID: "configId",
  ENTITY_TYPE: "entityType",
  FIELD_NAME: "fieldName",
  TC_ID: "tcId",
  LOGIN_SUCCESS_REDIRECT_PAGE: "returnPage",
};

const DASHBOARD_PATH = "/dashboard";
const PRODUCT_SUMMARY_PATH = "/product-summary";

const ROUTE_BASE_URL = {
  DASHBOARD_PATH: DASHBOARD_PATH,
  DOCUMENT_CENTER: "/docs",
  API_LIBRARY: "/apis",
  TUTORIALS: "/docs/tutorials",
  PRODUCT_CONFIG: "/product-configuration",
  INSTITUTIONAL_CONFIGURATION: `${DASHBOARD_PATH}/institutional-configuration`,
  INSTITUTIONAL_CONFIGURATION_FORM: `${DASHBOARD_PATH}/institutional-configuration/configuration`,
  COMPONENT_CONFIG_INTERSTITIAL_SCREEN: "/component-config-interstitial-screen",
  PRODUCT_CONFIG_INTERSTITIAL_SCREEN: `${DASHBOARD_PATH}/product-config-interstitial-screen`,
  ACADEMY: "/academy",
  ACADEMY_UNAVAILABLE: `/academy-unavailable`,

  TRANS_CODE_FORM: `${DASHBOARD_PATH}/transaction-codes/configuration`,
  TRANS_CODE: `${DASHBOARD_PATH}/transaction-codes`,
  GENERAL_LEDGER: `${DASHBOARD_PATH}/general-ledger`,
  GENERAL_LEDGER_FORM: `${DASHBOARD_PATH}/general-ledger/config`,
  TAX_BENEFIT_PLANS: "/prod_usTbp",
  PLAN_TYPE: "/usTbpType",
  REQUEST_SERVICE: "https://service.finxact.com/support/home",
  PRODUCT_TYPES: "/prodType",
  PRODUCT_SUB_TYPES: "/prodSubType",
  IFX_ACCT_TYPE: "/prodGroup",
  DEPOSIT_CHARGE_OFF_COMPONENTS: "/componentDepChrgOff",
  FEE_COMPONENTS: "/componentFee",
  INTEREST_COMPONENTS: "/componentInt",
  LIMIT_COMPONENTS: "/componentLimit",
  NON_SUFFICIENT_FUNDS_COMPONENTS: "/componentNsf",
  REPAY_COMPONENTS: "/componentRepay",
  REWARDS_COMPONENTS: "/componentReward",
  RULE_COMPONENTS: "/componentRules",
  TERM_DEPOSIT_COMPONENTS: "/componentTd",
  TRANSACTION_COMPONENTS: "/componentTrnOpt",
  TRANSACTION_LIMITS: "/trnLimit",
  ACCUMULATED_TRANSACTION_LIMITS: "/accumTrnLimit",
  PRODUCT_PROGRAMS: "/prodProgram",
  PRODUCT_PROGRAM_GROUPS: "/prodProgramGroup",
  PRODUCT_PROGRAM_GROUPS_REL: "/prodProgramGroupRel",
  LOAN_DELINQUENCY: "/prod_bkLnDelq",
  SWEEPS: "/sweep",
  PRODUCT_SERVICES: "/prodSvc",
  FREQUENCY_MODELER: "/freqModeler",
  CLEAR_CACHE_CORE: "/emptyCache",
  TGUID_DECODER: "/tguidDecode",
  TGUID_ENCODER: "/tguidEncode",
  IMPLEMENTATION_MAIN_DASHBOARD: "/extensions/dashboard",
  IMPLEMENTATION_PROJECT: "/extensions/project",
  IMPLEMENTATION_BUILDS: "/extensions/build",
  IMPLEMENTATION_DEPLOYMENTS: "/extensions/deployments",
  IMPLEMENTATION_TIMESHIFTS: "/extensions/timeshifts",
  IMPLEMENTATION_ENV_HEALTH: "/environmentHealth",
  IMPLEMENTATION_DSL_LOG_MESSAGES: "/extensions/rulelog",
  DIAGNOSTIC_DASHBOARD: "/diagnosticsDashboard",
  ERROR_LOG: "/errLog",
  JOURNAL: "/journal",
  RELEASE_NOTES: "/release",
  SCHEMA_OVERVIEW: "/schema/overview",
  SCHEMA_AND_CHOICES: "/schema/and-choices",
  ANNOUNCEMENTS: "/news",
  GL_BALANCING: "/glBalancing",
  BATCH_RECONCILIATION: "/reconBatch",
  TRANSACTION_RECONCILIATION: "/reconTrn",
  SYSTEM_CALENDER: "/systemCalendarTimeline",
  POSN_CALENDER: "/posnCalEvents",
  RELEASE_CALENDER: "/releaseCalendar",
  EVENT_CONTEXT: "/eventContext",
  FILES: "/achCorporateOrigination",
  ACH_BATCH: "/achBatch",
  BATCH_EXCEPTIONS: "/achBatchException",
  ORIGINATION_INSTRUCTIONS: "/achOriginInstr",
  BATCH_INFORMATION: "/batchInformation",
  ACH_FILES: "/ach",
  CHECK_FILES: "/check",
  CORE_BATCH_FILES: "/batchTransaction",
  ACH_ORDERS: "/orderACH",
  CORE_ORDERS: "/orderCORE",
  WIRE_ORDERS: "/orderFEDWIRE",
  REPORTS: "/reports",
  GL_EXTRACTS: "/glExtracts",
  ESCHEATS_EXTRACTS: "/escheatExtracts",
  OPEN_EXCEPTION_EXTRACTS: "/openExceptionsExtracts",
  TAX_FILES_AND_EXTRACTS: "/taxFilesAndExtracts",
  ACH_FED_REVERSAL: "/achFedReversal",
  FDIC_370: "/fdic370",
  SWAGGER: "https://113226-swagger.dev-10.finxact.io/",
  CORE_SERVICING: "https://servicingdev.finxact.io/ngagebanking/",
  NETWORK: "/network",
  CUSTOMER_RELATIONSHIP_TYPE: `${DASHBOARD_PATH}/customer-relationship-types`,
  CUSTOMER_RELATIONSHIP_TYPE_CONFIG: `${DASHBOARD_PATH}/customer-relationship-types/crt-configuration`,
  PLATFORM_SETUP: `${DASHBOARD_PATH}/platform-setup`,
  PROFILE: "/account",
  ENV_KEYS: "/envKey",
  ACCESS_MANAGEMENT: "/access-management",
  ACCOUNT_ADMIN: "/admin/accounts/",
  COURSES_ADMIN: "/admin/courses/",
  ENVIRONMENT_ADMIN: "/admin/environments/",
  PROFILE_TEMPLATE_ADMIN: "/admin/profileTemplates",
  REPORT_ADMIN: "/admin/reports",
  CONSOLE_ROLES: "/admin/consoleRoles/",
  CORE_BUILDS_ADMIN: "/admin/core-builds/",
  ORGANIZATION_ADMIN: "/admin/organizations/",
  ROLE: "/role",
  PROFILE_SETUP: `/profile-setup`,
};

const ROUTE_PATH = {
  // Standalone
  ADDITIONAL_FEATURES_FULLPATH: `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${PRODUCT_SUMMARY_PATH}/add-components`,
  LEGACY_NEW_PRODUCT: `/prod_bk/new`,
  LEGACY_EDIT_PRODUCT: `/prod_bk/edit`,
  EXPLORE_PRODUCT_IN_LEGACY: `/prod_bk/explore`,
  PRODUCT_SUMMARY: PRODUCT_SUMMARY_PATH,
  PRODUCT_SUMMARY_FULLPATH: `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${PRODUCT_SUMMARY_PATH}`,
  PRODUCT_LAUNCHPAD: `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/product-launchpad`,
  PRODUCT_WALKTHROUGH: `/product-walkthrough`,
  PLATFORM_SETUP: `${DASHBOARD_PATH}/platform-setup`,
  PRODUCT_WALKTHROUGH_FULLPATH: `${ROUTE_BASE_URL["DASHBOARD_PATH"]}/product-walkthrough`,
  INSTITUTIONAL_WALKTHROUGH: `${ROUTE_BASE_URL["INSTITUTIONAL_CONFIGURATION"]}/institutional-walkthrough`,
  INSTITUTIONAL_WALKTHROUGH_FULLPATH: `${ROUTE_BASE_URL["INSTITUTIONAL_CONFIGURATION"]}/institutional-walkthrough`,
  PRODUCT_NAME_QUERY: `?${QUERY_PARAM_KEY["PRODUCT_NAME_KEY"]}=`,
  ENTITY_ID_QUERY: `?${QUERY_PARAM_KEY["ENTITY_ID"]}=`,
  IFX_ACCT_TYPE_QUERY: `${QUERY_PARAM_KEY.IFX_TYPE}=`,
  PROD_SUB_TYPE_QUERY: `${QUERY_PARAM_KEY.PROD_SUB_TYPE}=`,
  ACTION_QUERY: `${QUERY_PARAM_KEY.ACTION}=`,
  FLOW_NAME_QUERY: `${QUERY_PARAM_KEY.FLOW_NAME}=`,

  // Document Center
  DOCUMENT_DETAIL: `${ROUTE_BASE_URL["DOCUMENT_CENTER"]}/document-detail`,

  //API Library
  API_LIBRARY_DETAIL: `${ROUTE_BASE_URL["API_LIBRARY"]}/detail-page`,

  // Fee deposit
  FEE_GENERAL_SETUP: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/fee-general-feature-setup`,
  FEE_CONFIGURATION: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/fee-configuration`,
  FEE_SERVICE_CHARGE_DETAILS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/fee-service-charge-details`,
  FEE_EARNING_ANALYSIS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/fee-earning-analysis`,

  // Interest Deposit
  INTEREST_GENERAL_FEATURE_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/interest-general-feature-form`,
  INTEREST_FOUNDATIONAL_INTEREST_DETAILS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/foundational-interest-detail`,
  INTEREST_INDEX_RATE_FOUND_RATE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/indexed-rate-foundational-detail`,
  INTEREST_INDEX_RATE_ADJUSTMENT: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/rate-adjustment`,
  INTEREST_INDEX_RATE_REVIEW_OFFSET: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/indexed-rate-offset-terms`,
  INTEREST_INDEX_RATE_REVIEW_FREQUENCY: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/review-frequency`,
  INTEREST_PROMOTIONAL_FOUND_DETAIL_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/promotional-foundational-details-form`,
  INTEREST_PROMOTIONAL_RATE_ADJUST_RANGE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/promotional-rate-adjust-ranges`,
  INTEREST_PROMOTIONAL_RATE_OFFSET_TERMS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/promotional-rate-offset-terms`,
  INTEREST_POST_FREQUENCY_ROUNDING: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/post-frequency`,
  INTEREST_ACCURAL_RATE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/interest-accural-details`,

  // Interest Loan
  INTEREST_LOAN_GENERAL_FEATURE_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-interest-general-feature-form`,
  INTEREST_LOAN_FOUNDATIONAL_INTEREST_DETAILS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-foundational-interest-detail`,
  INTEREST_LOAN_INDEX_RATE_FOUND_DETAIL: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-indexed-rate-foundational-detail`,
  INTEREST_LOAN_INDEX_RATE_ADJUSTMENT: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-rate-adjustment`,
  INTEREST_LOAN_INDEX_RATE_REVIEW_OFFSET: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-indexed-rate-offset-terms`,
  INTEREST_LOAN_INDEX_RATE_REVIEW_FREQUENCY: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-review-frequency`,
  INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-promotional-foundational-details-form`,
  INTEREST_LOAN_PROMOTIONAL_RATE_ADJUST_RANGE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-promotional-rate-adjust-ranges`,
  INTEREST_LOAN_PROMOTIONAL_RATE_OFFSET_TERMS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-promotional-rate-offset-terms`,
  INTEREST_LOAN_POST_FREQUENCY_ROUNDING: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-post-frequency`,
  INTEREST_LOAN_ACCURAL_RATE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-interest-accural-details`,

  // Limit deposit
  LIMIT_GENERAL_FEATURE_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/limit-general-feature-form`,
  LIMIT_ACCOUNT_BALANCE_CONFIG: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/account-balance-configuration`,
  LIMIT_RESTRICTION_CONFIG: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/restriction-configuration`,
  LIMIT_ACCUMULAT_TRANSCATION_CONFIG: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/accumulated-transaction-limit-config`,
  LIMIT_SINGLE_TRANSACTION_CONFIG: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/single-transaction-limit-config`,

  // rewards deposit
  REWARD_GENERAL_FEATURE_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/rewards-general-feature-setup`,
  REWARD_CONFIGURATION_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/reward-configuration`,

  // repayment loan
  REPAYMENT_GENERAL_FEATURE_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/repayment-general-component-setup`,
  REPAYMENT_PRINCIPAL_INTEREST_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/repayment-principal-and-interest-configuration`,
  REPAYMENT_PAYMENT_CONFIGURATION_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/repayment-payment-configuration`,
  REPAYMENT_PAYMENT_DIRECTION_CHARGES: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/repayment-payment-direction-charges`,
  REPAYMENT_PRE_PAYMENT_DIRECTION_CHARGES: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/repayment-pre-payment-configurations`,
  REPAYMENT_PAST_DUE_CONFIGURATIONS_AND_TRACKING: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/repayment-past-due-configurations-and-tracking`,
  REPAYMENT_ADVANCED_COMPONENT_OPTIONS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/repayment-advanced-component-options`,

  // fee loan
  FEE_GENERAL_SETUP_LOAN: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/fee-general-component-setup-loan`,
  FEE_CONFIGURATION_LOAN: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/fee-configuration-loan`,
  FEE_SERVICE_CHARGE_DETAILS_LOAN: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/fee-service-charge-details-loan`,
  FEE_EARNING_ANALYSIS_LOAN: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/fee-earning-analysis-loan`,

  // collateral loan
  COLLATERAL_GENERAL_COMPONENT_SETUP: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/collateral-general-component-setup`,
  COLLATERAL_SECURITY_SECIFICATIONS_OPTIONS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/collateral-security-specifications`,

  //rules
  RULES: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/rules`,

  //product attributes [Please update the newly added url to product-config/loyout for sub-title display]
  PRODUCT_ATTRIBUTE_BASIC_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/basic-product-form`,
  PRODUCT_ATTRIBUTE_CADENCES_FORM: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/cadences-form`,
  PRODUCT_ATTRIBUTE_PRODUCT_FEATURES: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/additional-product-feature`,
  PRODUCT_ATTRIBUTE_REGULATORY_ADHERENCE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/regulatory-adherence-limitations`,
  PRODUCT_ATTRIBUTE_INSTALLMENT_REGULATORY_ADHERECE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/installment-regulatory-adherence-limitations`,
  PRODUCT_ATTRIBUTE_ELIGIBILITY_AND_COMPONENTS_CONFIGURATION: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/eligibility-and-components-configuration`,
  PRODUCT_ATTRIBUTE_LOAN_DELINQUENCY: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/loan-delinquency`,
  PRODUCT_ATTRIBUTE_LOAN_DELINQUENCY_WIHTOUT_BASE: `loan-delinquency`,
  PRODUCT_ATTRIBUTE_ADHERENCE_LIMI_WIHTOUT_BASE: `adherence-limitations`,

  //nsf deposit
  NSF_GENERAL_SETUP: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/nsf-general-feature-setup`,
  NSF_NEGATIVE_LIMITS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/negative-limits-details`,
  NSF_TRANSACTIONAL_CODE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/nsf-transactions-code-specification`,

  //transaction deposit
  TRANSACTION_GENERAL_SETUP: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/transaction-general-feature-setup`,
  TRANSACTION_TRANSACTIONAL_CODE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/transaction-code-specifications`,

  //charge off deposit
  CHARGE_OFF_GENERAL_SETUP: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/charge-off-general-feature-setup`,
  CHARGE_OFF_TIMING_AND_THRESHOLD: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/charge-off-timing-and-threshold`,
  CHARGE_OFF_TRANSACTIONAL_CODE: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/charge-off-transaction-code`,

  //term deposit
  TERM_DEPOSIT_GENERAL_SETUP: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/term-general-feature-setup`,
  TERM_DEPOSIT_MATURITY_CONFIGURATION: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/term-maturity-configuration`,
  TERM_DEPOSIT_ROLL_OVER_PRODUCT: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/term-rollover-product-interest`,
  TERM_DEPOSIT_GRACE_PERIODS: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/term-grace-periods`,
  TERM_DEPOSIT_PENALTIES_ADJUSTMENT: `${ROUTE_BASE_URL.PRODUCT_CONFIG}/term-penalties-adjustment`,

  //Interstitial Screen interstitial-screen
  IC_INTERSTITIAL_SCREEN: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION}/interstitial-screen`,
  GL_INTERSTITIAL_SCREEN: `${ROUTE_BASE_URL.GENERAL_LEDGER}/interstitial-screen`,
  TC_INTERSTITAL_SCREEN: `${ROUTE_BASE_URL.TRANS_CODE}/configuration/interstitial-screen`,
  CRT_INTERSTITAL_SCREEN: `${ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE}/interstitial-screen`,

  //Completion Screen interstitial-screen
  IC_COMPLETION_SCREEN: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION}/completion-screen`,
  GL_COMPLETION_SCREEN: `${ROUTE_BASE_URL.GENERAL_LEDGER}/completion-screen`,

  //Component Config Interstitial Screen
  COMPONENT_CONFIG_INTERSTITIAL_SCREEN: `${ROUTE_BASE_URL.COMPONENT_CONFIG_INTERSTITIAL_SCREEN}`,
  PRODUCT_CONFIG_INTERSTITIAL_SCREEN: `${ROUTE_BASE_URL.PRODUCT_CONFIG_INTERSTITIAL_SCREEN}`,

  //IC-Account Group
  IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/account-group-foundational-details`,
  IC_ACCOUNT_GROUP_NUMBERING_IDENTIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/numbering-identification`,
  IC_ACCOUNT_GROUP_COMPOSITE_FIELD_CONFIGURATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/composite-field-configuration`,
  IC_ACCOUNT_GROUP_ADVANCED_CONFIGURATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/advanced-configurations`,

  //Asset Catalog
  IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/asset-catalog-foundational-details`,
  IC_ASSET_ORIGIN_AND_CLASSIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/asset-origin-and-classification`,
  IC_ASSET_FINANCIAL_SPECIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-specifications`,
  IC_ASSET_GENERAL_LEDGER_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-ledger-details`,

  //Account Types
  IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/account-type-foundational-details`,
  IC_ACCOUNT_TYPES_ADD_LIMIT: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/account-type-add-limit`,
  IC_ACCOUNT_TYPES_RELATIONSHIP_FOUNDATIONAL_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/account-type-relationship-foundational-details`,
  IC_ACCOUNT_TYPES_RELATIONSHIP_PARTY_SPECIFICATIONS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/account-type-relationship-party-specifications`,
  IC_ACCOUNT_TYPES_RELATIONSHIP_ADDITIONAL_SELECTIONS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/account-type-relationship-additional-selections`,
  IC_ACCOUNT_TYPES_RELATIONSHIP_SUMMARY: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/account-type-rel-summary`,

  // Financial Calender
  IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-calender-foundational-details`,
  IC_FINANCIAL_CALENDER_BUSINESS_DAY_CONFIG: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-calender-business-day-configuration`,
  IC_FINANCIAL_CALENDER_HOLIDAY_CONFIGURATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-calender-holiday-configuration`,

  //IC-Financial Institution Parameters (Bank) Parameters
  IC_BANK_PARAMS_FOUNDATION_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/foundational-details`,
  IC_BANK_PARAMS_RATES_CURRENCIES_IDENTIFIERS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/rates-currencies-identifiers`,
  IC_BANK_PARAMS_CUTOFF_LIMITS_THRESHOLD: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/cutoffs-limits-thresholds`,
  IC_BANK_PARAMS_ADDITIONAL_SPECIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/additional-specifications`,
  IC_BANK_PARAMS_BASE_SAVING_RATE: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/base-savings-rate`,
  IC_BANK_PARAMS_US_FINANCIAL_INST_PARAMS_INFO: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/us-financial-institution-parameters-information`,
  IC_BANK_PARAMS_FDIC_370_RELATED_FIELD: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/fdic-related-fields`,
  IC_BANK_PARAMS_SBA_LENDING_PARTY_EMAIL: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/sba-lending-party-email`,
  IC_BANK_PARAMS_SBA_LENDING_PARTY_PHONE: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/sba-lending-party-phone`,
  IC_BANK_PARAMS_SCRA_PROPERTIES: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/scra-properties`,
  IC_BANK_PARAMS_TAX_LENDING_PARTY_PHONE: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/tax-party-phone-contact`,
  IC_BANK_PARAMS_GL_EXTRACT_FILE_FORMATE: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/gl-ext-file-format`,
  IC_BANK_PARAMS_LOAN_PENALTY_RATE: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/loan-penalty-rate`,
  IC_BANK_PARAMS_PRIORITY_POSTING: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/priority-posting`,
  IC_BANK_PARAMS_TAX_PARTY_EMAIL_CONTACT: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/tax-party-email-contact`,

  //General party parameter
  GENERAL_PARTY_PARAMETER: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter`,
  GENERAL_PARTY_PARAMETER_IDENTIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/general-party-identification`,
  GENERAL_PARTY_PARAMETER_PARTY_ADDRESS_PARAMETER_DETAIL: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/party-address-details`,
  GENERAL_PARTY_PARAMETER_LOCATION_CONTACT_INFORMATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/location-contact-information`,
  GENERAL_PARTY_PARAMETER_GENERAL_PARTY_SPECIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/general-party-specification`,
  GENERAL_PARTY_PARAMETER_TAX_SPECIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/tax-specification`,
  GENERAL_PARTY_PARAMETER_PROCESS_FLAGS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/process-flags`,
  GENERAL_PARTY_PARAMETER_INTERNATIONAL_TRANSFER_INTENT: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/international-transfer-intent`,
  GENERAL_PARTY_PARAMETER_EXTERNAL_REFERENCES: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/external-references`,
  GENERAL_PARTY_PARAMETER_PARTY_EMAILS_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/party-emails-details`,
  GENERAL_PARTY_PARAMETER_PARTY_PHONE_DETAILS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-party-parameter/party-phone-details`,

  //General Organization party parameter
  IC_GENERAL_ORGANIZATION_PARTY: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-organization-party-parameters`,
  IC_GENERAL_ORGANIZATION_PARTY_IDENTIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-organization-party-parameters/general-organization-party-identification`,
  IC_GENERAL_ORGANIZATION_PARTY_TAX_SPECIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-organization-party-parameters/tax-specifications`,
  IC_GENERAL_ORGANIZATION_PARTY_SPECIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-organization-party-parameters/general-organization-party-specifications`,
  IC_GENERAL_ORGANIZATION_PARTY_LOCATION_AND_CONTACT: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/general-organization-party-parameters/location-contact-info`,

  //Financial Organization specific parameters
  IC_FIN_ORG_ID_QUERY: `?${QUERY_PARAM_KEY["ENTITY_ID"]}=`,
  IC_CORRS_INDEX_QUERY: `&${QUERY_PARAM_KEY["FIN_ORG_CORRS_INDEX"]}=`,
  IC_FIN_ORG_SPEC_PARAM: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters`,
  IC_FIN_ORG_SPEC_PARAM_FIN_ORG_IDENTIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters/financial-organization-identification`,
  IC_FIN_ORG_SPEC_PARAM_FIN_ORG_OTHER_IDENTIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters/other-identification-details`,
  IC_FIN_ORG_SPEC_PARAM_FIN_ORG_SPECIFICATIONS: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters/financial-organization-specifications`,
  IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_IDENTIFICATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters/corresponding-network-identification`,
  IC_FIN_ORG_SPEC_PARAM_CORRS_DESTINATION_NETWORK: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters/financial-organziation-destination-network`,
  IC_FIN_ORG_SPEC_PARAM_CORRS_ORIGIN_NETWORK: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters/financial-organziation-origin-network`,
  IC_FIN_ORG_SPEC_PARAM_CORRS_NETWORK_SUMMARY: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters/financial-organization-corresponding-summary`,
  IC_FIN_ORG_SPEC_PARAM_RULES: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-specific-parameters/financial-organization-rules`,

  //Party US Bank Information
  PARTY_US_BANK_INFO: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/party-us-bank-parameter`,
  PARTY_US_SPECIFICATION_FINANCIAL_ORGANIZATION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/party-us-bank-parameter/us-specific-financial-organization-identification`,
  PARTY_US_FEDWIRE_FORM: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/party-us-bank-parameter/fedwire-details`,

  //Financial Organization Party Limit
  IC_FIN_ORG_PARTY_LIMIT: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-party-limit`,
  IC_FIN_ORG_PARTY_LIMIT_ADD_LIMIT: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-party-limit/add-limit`,
  IC_FIN_ORG_PARTY_LIMIT_ACCUMULATED_TRANSACTION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-party-limit/accumulated-transaction-limit`,
  IC_FIN_ORG_PARTY_LIMIT_TEMP_ACCUMULATED_TRANSACTION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-party-limit/temporary-accumulated-transaction-limit`,
  IC_FIN_ORG_PARTY_LIMIT_SINGLE_TRANSACTION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-party-limit/single-transaction-limit`,
  IC_FIN_ORG_PARTY_LIMIT_TEMP_SINGLE_TRANSACTION: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-party-limit/temporary-single-transaction-limit`,
  IC_FIN_ORG_PARTY_LIMIT_GENERAL_LIMIT: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION_FORM}/financial-organization-party-limit/general-limit-details`,

  //Academy
  ACADEMY_CATALOG: `${ROUTE_BASE_URL.ACADEMY}/catalog`,
  ACADEMY_CATALOG_BADGE_PATH: `${ROUTE_BASE_URL.ACADEMY}/catalog/badge-path`,
  ACADEMY_BADGES_CERTIFICATION: `${ROUTE_BASE_URL.ACADEMY}/badges-certifications`,
  ACADEMY_CATALOG_COURSE: `${ROUTE_BASE_URL.ACADEMY}/catalog/course`,
  ACADEMY_CERTIFICATION_PATH: `${ROUTE_BASE_URL.ACADEMY}/badges-certifications/certification-path`,
  ACADEMY_OVERVIEW: `${ROUTE_BASE_URL.ACADEMY}/overview`,

  //General Ledger
  GL_ACCOUNTING_SEGMENTS: `${ROUTE_BASE_URL.GENERAL_LEDGER_FORM}/accounting-segments`,
  GL_ACCOUNTS: `${ROUTE_BASE_URL.GENERAL_LEDGER_FORM}/accounts`,
  GL_SYSTEM_ACCOUNTS: `${ROUTE_BASE_URL.GENERAL_LEDGER_FORM}/system-accounts`,
  GL_SETS: `${ROUTE_BASE_URL.GENERAL_LEDGER_FORM}/sets`,
  GL_POSITIONS: `${ROUTE_BASE_URL.GENERAL_LEDGER_FORM}/positions`,
  GL_WALKTHROUGH: `${ROUTE_BASE_URL.GENERAL_LEDGER}/general-ledger-walkthrough`,

  //Transaction Code
  TC_ENTRY_INDEX_PARAM: `?${QUERY_PARAM_KEY.TC_ENTRY_INDEX}=`,
  TC_LANDING_PAGE: `${ROUTE_BASE_URL.TRANS_CODE}`,
  TC_FOUNDATIONAL_DETAILS: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/tc-foundational-details`,
  TC_TAG_SETUP: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/tag-setup`,
  TC_ENTRY_SETUP: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/entry-setup`,
  TC_TRANSACTION_ENTRY_FOUNDATIONAL_DETAILS: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-entry-foundational-details`,
  TC_TRANSACTION_ENTRY_ADVANCED_SETUP: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-entry-advanced-setup`,
  TC_TRANSACTION_ENTRY_STATISTIC_GROUP: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-entry-statistic-group`,
  TC_TRANSACTION_ENTRY_ACCOUNTING_SEGMENTS: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-accounting-segments`,
  TC_TRANSACTION_ENTRY_HOLD_SPECIFICATION: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-entry-hold-specifications`,
  TC_TRANSACTION_ENTRY_SUMMARY: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-entry-summary`,
  TC_ACCESS_RIGHTS: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/access-rights`,
  TC_WALKTHROUGH: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-code-walkthrough`,
  TC_TRANSACTION_ENTRY_HOLD_SPECIFICATIONS: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-entry-hold-specifications`,
  TC_TRANSACTION_ENTRY_FIELD_SPECIFICATIONS: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/transaction-entry-field-specifications`,
  TC_TRANSACTION_ENTRY_TEMPLATES: `${ROUTE_BASE_URL.TRANS_CODE_FORM}/templates`,

  //CRT
  CRT_LANDING_PAGE: `${ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE}`,
  CRT_WALKTHROUGH: `${ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE}/customer-relationship-types-walkthrough`,
  CRT_GENERAL_SETUP: `${ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE}/crt-configuration/general-setup`,
  CRT_PARTY_RELATIONSHIPS: `${ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE}/crt-configuration/party-relationships`,

  //access management
  AM_PROFILE_TEMPLATE: `${ROUTE_BASE_URL.ACCESS_MANAGEMENT}/profile-template`,
  AM_TEAM: `${ROUTE_BASE_URL.ACCESS_MANAGEMENT}/team`,
  AM_CUSTOM_ROLES: `${ROUTE_BASE_URL.ACCESS_MANAGEMENT}/custom-roles`,

  //tutorials
  TUTORIAL_DETAIL: `${ROUTE_BASE_URL["TUTORIALS"]}/detail-page`,
};

export { ROUTE_BASE_URL, ROUTE_PATH, QUERY_PARAM_KEY };
