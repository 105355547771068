import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";

const modalBtnProps = {
  primaryBtnProp: {
    name: "Save",
    btnClassName: "app-btn-inverse-secondary",
  },
  secondaryBtnProp: {
    name: "Cancel",
    btnClassName: "app-btn-reg-secondary",
  },
};

const modalSelectorBtnProps = {
  primaryBtnProp: {
    name: "Save",
    btnClassName: "app-btn-inverse-secondary",
  },
  secondaryBtnProp: {
    name: "Cancel",
    btnClassName: "app-btn-reg-secondary",
  },
};
const ROUTES_TO_IGNORE_DEFAULT_CONSOLE_SIDEBAR: Readonly<string[]> = [
  ROUTE_BASE_URL["INSTITUTIONAL_CONFIGURATION_FORM"],
  ROUTE_BASE_URL["PRODUCT_CONFIG"],
  ROUTE_BASE_URL["GENERAL_LEDGER_FORM"],
  ROUTE_BASE_URL["TRANS_CODE_FORM"],
  ROUTE_BASE_URL["CUSTOMER_RELATIONSHIP_TYPE_CONFIG"],
  ROUTE_PATH["PRODUCT_WALKTHROUGH"],
  ROUTE_PATH["INSTITUTIONAL_WALKTHROUGH"],
  ROUTE_PATH["PRODUCT_CONFIG_INTERSTITIAL_SCREEN"],
  ROUTE_PATH["COMPONENT_CONFIG_INTERSTITIAL_SCREEN"],
  ROUTE_PATH["IC_INTERSTITIAL_SCREEN"],
  ROUTE_PATH["GL_INTERSTITIAL_SCREEN"],
  ROUTE_PATH["GL_WALKTHROUGH"],
  ROUTE_PATH["TC_INTERSTITAL_SCREEN"],
  ROUTE_PATH["CRT_WALKTHROUGH"],
  ROUTE_PATH["CRT_INTERSTITAL_SCREEN"],
  ROUTE_PATH["PLATFORM_SETUP"],
  ROUTE_PATH["IC_COMPLETION_SCREEN"],
  ROUTE_PATH["GL_COMPLETION_SCREEN"],
  ROUTE_BASE_URL.PROFILE_SETUP,
];

export {
  modalBtnProps,
  ROUTES_TO_IGNORE_DEFAULT_CONSOLE_SIDEBAR,
  modalSelectorBtnProps,
};
