"use client";
import {
  AlertStatus,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@/components/ChakraUiManager";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  AddNewButton,
  AppFormLabel,
  AppPortal,
  CardContainer,
  InputText,
  StatusBubble,
  FeatureTooltip,
  FormFieldMsg,
} from "../../../common";
import css from "./ProductSummary.module.scss";
import styleVars from "@/styles/_export.module.scss";
import {
  QUERY_PARAM_KEY,
  ROUTE_BASE_URL,
  ROUTE_PATH,
} from "@/route-config/route-path";

import {
  DEPOSIT_COMPONENT_CLASS_FOR_SUMMARY,
  getCompleteAttributeList,
  getCurrentProductFormLinkToNavigate,
  LOAN_COMPONENT_CLASS_FOR_SUMMARY,
  PRODUCT_SUMMARY_STEPS,
  PRODUCT_SUMMARY_STEPS_LOAN,
  ProductSummaryFeatureDetailSteps,
  ProductSummaryType,
} from "../product-summary-config";
import {
  DASHBOARD_ACCORDION_STATUS,
  IFX_ACCT_GL_CATEGORY,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_ACCT_TYPE,
  IFX_POSN_CLASS_TYPE,
  IFX_PRODUCT_TYPE_VAL,
  IfxAcctProductType,
  PRODUCT_CLASS_NAME,
} from "@/utils/constants";
import {
  ProductType,
  ProductWorkflowModelType,
  getFeatureStatus,
  constructProductSummaryFeatureDetails,
  updateProductInSummary,
  mapSelectedAttributeData,
  ProductComponentType,
  getIfxProdType,
  getRemovedComponent,
} from "@/components/product-management";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  getSelectedFeatureData,
  updateCompsVerAndCreateProd,
  updateSelectedAttributeOptionsFromApi,
} from "../../product-configuration/product-config-client-service";
import {
  createWorkflowGeneric,
  deleteWorkflowStage,
  getWorkflowGeneric,
} from "@/api-config/api-service";
import {
  arrowForwardBlack,
  arrowForwardBlue,
  IconEdit,
  IconFeatureDetails,
  dotPatternWalkthrough,
} from "public/assets";
import Image from "next/image";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { useSelector } from "react-redux";
import { getEntitlement, getEnvIsDisable } from "@/store";
import { useSearchParams } from "next/navigation";
import ProductSummaryFeatureDetails from "../product-summary-feature-details/ProductSummaryFeatureDetails";
import { checkReadOnlyEntitlement } from "@/utils/common";
import { deepCopy } from "@finxact/finxact-shared-ui";
import { useEffectOnce } from "react-use";

export type SelectedComponent = ProductComponentType & {
  steps: ProductSummaryFeatureDetailSteps[];
  name?: undefined;
  link?: undefined;
};

export enum DetailType {
  component = "component",
  product = "product",
}

type FeatureCardProps =
  | {
      component: ProductComponentType;
      selected: boolean;
      isAdditionalAttributes: false;
      handleSelectedFeature?: () => void;
    }
  | {
      component: ProductSummaryType;
      selected: boolean;
      isAdditionalAttributes: true;
      handleSelectedFeature?: () => void;
    };

function constructFeatureCardClass(selected: boolean) {
  return {
    p: 8,
    marginBottom: 6.4,
    borderRadius: "8px",
    boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.10)",
    gap: 6.4,
    backgroundColor: "white",
    cursor: "pointer",
    zIndex: 1,
    marginRight: 28,
    ...(selected
      ? {
          clipPath: "inset(-15px 0px -15px -15px)",
          boxShadow: "-6px 0px 14px 0px rgba(0, 0, 0, 0.30)",
          borderRightRadius: 0,
          border: `2px solid ${styleVars.appDefaultLinkColor}`,
          borderRight: "none",
          marginRight: "-2.3px",
        }
      : {}),
  };
}

// Not placing this in components because styling of the card only is specific to this screen
const FeatureCard = (props: FeatureCardProps) => {
  const { component, selected, handleSelectedFeature, isAdditionalAttributes } =
    props;
  return (
    <Flex
      sx={constructFeatureCardClass(selected)}
      onClick={handleSelectedFeature}
    >
      <HStack w="100%">
        <StatusBubble
          status={getFeatureStatus(component?.status || "")}
          iconWidth={24}
        />
        <Text
          as="span"
          fontSize="20px"
          fontWeight={600}
          lineHeight="28px"
          paddingLeft="20px"
        >
          {isAdditionalAttributes ? component.name : component.componentName}
        </Text>
        <Spacer />
        <Image
          src={selected ? arrowForwardBlue : arrowForwardBlack}
          alt="Right arrow 1"
        />
      </HStack>
    </Flex>
  );
};

export default function ProductSummary() {
  //context API
  const {
    isQuickStartFlow,
    productDetails,
    productWorkflowData,
    navigateWithProductNameParam,
    navigateTo,
    setSummaryQuickStartFlag,
    updateSidebarMenu,
    setProductWorkflowData,
    setProductRefetchState,
    tooltipFlyoutDetails,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);
  const [productAttributesMetaData, setProductAttributesMetaData] = useState<
    ProductSummaryType[]
  >([]);
  const [isEditing, toggleEditState] = useState(true);
  const [showComponentSection, toggleComponentSection] =
    useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] =
    useState<SelectedComponent | null>(null);
  const [selectedAttribute, setSelectedAttributes] =
    useState<ProductSummaryType | null>(null);
  const [prodNameDesc, setProdNameDesc] = useState({
    name: "",
    desc: "",
  });
  const toast = useToast();
  const envIsDisabled = useSelector(getEnvIsDisable);
  const entitlement = useSelector(getEntitlement);
  const prodType = IFX_ACCT_PRODUCT_TYPE[productDetails?.ifxAcctType!];
  const checkEntitlement = checkReadOnlyEntitlement(entitlement, prodType);
  const queryParam = useSearchParams();
  const currProductId = queryParam.get(QUERY_PARAM_KEY.PRODUCT_NAME_KEY);
  const ifxAcctType = queryParam.get(QUERY_PARAM_KEY.IFX_TYPE);
  const ifxAcctProductSubType = queryParam.get(QUERY_PARAM_KEY.PROD_SUB_TYPE);

  function getComponentSteps() {
    return getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan
      ? deepCopy(PRODUCT_SUMMARY_STEPS_LOAN)
      : deepCopy(PRODUCT_SUMMARY_STEPS);
  }

  const handleComponentClick =
    (component: ProductComponentType) => async () => {
      if (component.componentClass && productDetails) {
        const componentSteps = getComponentSteps();
        const currStep = deepCopy(componentSteps[component.componentClass]);

        let updatedCurrStep = currStep?.length ? currStep : [];
        if (
          productDetails.components?.find(
            (component) => component.componentClass === component.componentClass
          )?.componentName
        ) {
          const componentData = await getSelectedFeatureData(
            component.componentClass,
            component.componentName ?? "",
            component.version ?? 1
          )
            .then((data) => data)
            .catch((error) => {
              console.error(error);
            });
          const workFlowData = productWorkflowData?.length
            ? productWorkflowData
            : await getWorkflowGeneric({
                model: API_ROUTE_CONFIGURATION.product,
                key: productDetails.name,
              });
          const currentStageStatus = workFlowData?.length
            ? workFlowData?.find(
                (workFlowItem: ProductWorkflowModelType) =>
                  workFlowItem.stage === component.componentClass
              )?.status
            : DASHBOARD_ACCORDION_STATUS.completed;
          const currentStageIndex = currStep?.findIndex(
            (step) => step.stageStatus === currentStageStatus
          );
          updatedCurrStep = constructProductSummaryFeatureDetails(
            currStep,
            componentData,
            currentStageIndex
          );
        }

        setSelectedComponent({
          ...component,
          steps: (updatedCurrStep ?? []) as ProductSummaryFeatureDetailSteps[],
        });
      }
      setSelectedAttributes(null);
    };

  const handleAttributeClick = (attribute: ProductSummaryType) => async () => {
    const updatedProductDetails = await updateSelectedAttributeOptionsFromApi(
      productDetails!
    );

    const updatedAttributeData = mapSelectedAttributeData(
      deepCopy(updatedProductDetails),
      attribute
    );
    setSelectedAttributes(updatedAttributeData);
    setSelectedComponent(null);
  };

  const _handleNameOrDescChange = (key: "name" | "desc") => (value: string) => {
    setProdNameDesc((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  function getInitialValForNewProduct(): ProductType {
    let prodType: IfxAcctProductType;
    const updatedProdDetails = deepCopy(productDetails || {}) as ProductType;
    if (ifxAcctType && ifxAcctProductSubType) {
      updatedProdDetails.ifxAcctType = ifxAcctType as IFX_ACCT_TYPE;
      updatedProdDetails.glCat = IFX_ACCT_GL_CATEGORY[ifxAcctType];
      prodType = IFX_ACCT_PRODUCT_TYPE[ifxAcctType];
      if (prodType) {
        updatedProdDetails.prodType = prodType;
        updatedProdDetails.posnClass = IFX_POSN_CLASS_TYPE[prodType];
      }
      updatedProdDetails.prodSubType = ifxAcctProductSubType;
    }
    updatedProdDetails.name = prodNameDesc.name;
    updatedProdDetails.desc = prodNameDesc.desc;
    return updatedProdDetails;
  }

  async function saveProductName() {
    // Updating existing product
    if (currProductId && !isQuickStartFlow) {
      updateExistingProduct();
    } else if (prodNameDesc.name) {
      // Creating a new product / Copying an existing product / Building a pdt from an accelerator */
      const newProdDetails = getInitialValForNewProduct();
      const shouldCreateWorkflow = !isQuickStartFlow;
      // No components while creating a new product
      if (shouldCreateWorkflow && newProdDetails.hasOwnProperty("components")) {
        delete newProdDetails.components;
      }

      await updateCompsVerAndCreateProd(
        // New products cannot be accelerators
        { ...newProdDetails, isAccelerator__: false },
        isQuickStartFlow
      )
        .then(async (res) => {
          if (res?.name) {
            showToast(
              "add-product-summary-success",
              "Saved the product successfully",
              "success"
            );
            toggleComponentSection(true);
            toggleEditState(false);
            updateWorkflow(res, shouldCreateWorkflow);
          }
        })
        .catch((err: any) => {
          toggleComponentSection(false);
          toggleEditState(true);
          const errMsg =
            err?.errors[0]?.errorDesc || "Unable to save the product";
          showToast("add-product-summary-error", errMsg, "error");
        });
    }
  }

  async function updateWorkflow(
    prodDetails: ProductType,
    shouldCreateWorkflow: boolean
  ) {
    const updateProductPath = () => {
      setProductRefetchState(true);
      //setting quickstart flow to false
      setSummaryQuickStartFlag?.(false);
      //this will change the route url without the state change
      navigateWithProductNameParam(
        `${ROUTE_PATH["PRODUCT_SUMMARY_FULLPATH"]}`,
        prodDetails.name
      );
    };
    const completeProductList = [
      ...(prodDetails?.components ?? []),
      ...productAttributesMetaData,
    ];
    if (shouldCreateWorkflow) {
      await Promise.all(
        completeProductList.map(async (component) => {
          await createWorkflowGeneric({
            model: API_ROUTE_CONFIGURATION.product,
            key: prodDetails?.name ?? "",
            stage: component?.componentClass ?? "",
            status: DASHBOARD_ACCORDION_STATUS.notStarted,
          });
        })
      ).then(updateProductPath);
    } else {
      updateProductPath();
    }
  }

  function updateExistingProduct() {
    if (!productDetails) {
      console.error("Product details shouldn't be undefined");
      return;
    }
    updateProductInSummary({ ...productDetails, desc: prodNameDesc.desc })
      .then(() => {
        showToast(
          "updated-product-summary-success",
          "Updated the product successfully",
          "success"
        );
        toggleEditState(false);
        setProductRefetchState(true);
      })
      .catch((err) => {
        toggleEditState(true);
        const errMsg =
          err?.errors[0]?.errorDesc || "Unable to update the product";
        showToast("updated-product-summary-error", errMsg, "error");
      });
  }

  async function deleteRemovedComponentsWorkflow(prodData: ProductType) {
    setSelectedComponent(null);

    const updatedComponentsList = prodData.components!.map(
      (comp) => comp.componentClass
    );
    const componentStages = productWorkflowData?.filter(
      (item) => PRODUCT_CLASS_NAME[item.stage]
    );
    if (componentStages?.length) {
      const removedComponents = getRemovedComponent(
        updatedComponentsList,
        componentStages
      );
      if (removedComponents.length) {
        await Promise.all(
          removedComponents.map(async (component) => {
            await deleteWorkflowStage({
              productName: prodData?.name ?? "",
              stage: component.stage,
            });
          })
        );
        getWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: currProductId!,
        }).then((data) => {
          setProductWorkflowData?.(data);
        });
      }
    }
  }

  function showToast(toastId: string, msg: string, status: AlertStatus) {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        description: msg,
        status: status,
        duration: 5000,
      });
    }
  }

  function setDefaultAttributes() {
    let updatedProductAttributeData = deepCopy(
      getCompleteAttributeList(ifxAcctType ?? "")
    );
    updatedProductAttributeData = updatedProductAttributeData.map(
      (attributeData: ProductSummaryType) => ({
        ...attributeData,
        status: DASHBOARD_ACCORDION_STATUS.notStarted,
      })
    );
    setProductAttributesMetaData(updatedProductAttributeData);
  }

  const componentWithMetaData = useMemo(() => {
    const productTypeConfig =
      getIfxProdType(productDetails) === IFX_PRODUCT_TYPE_VAL.loan
        ? LOAN_COMPONENT_CLASS_FOR_SUMMARY
        : DEPOSIT_COMPONENT_CLASS_FOR_SUMMARY;
    return (
      productDetails?.components?.map((comp) => ({
        ...productTypeConfig[comp.componentClass],
        ...comp,
      })) ?? []
    );
  }, [productDetails]);

  const navigateToProductConfig = async (path?: string) => {
    if (!path) {
      if (productWorkflowData?.length) {
        const href = getCurrentProductFormLinkToNavigate(
          productDetails!,
          productWorkflowData
        );
        navigateTo(href);
        return;
      }
      // No workflow exists, product in completed state
      if (componentWithMetaData.length) {
        // navigate to the first step of the first component
        navigateWithProductNameParam(
          `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${componentWithMetaData[0].link}`
        );
      } else {
        // if no components exist, move to product basics screen
        navigateWithProductNameParam(
          `${ROUTE_BASE_URL["DASHBOARD_PATH"]}${ROUTE_PATH.PRODUCT_ATTRIBUTE_BASIC_FORM}`
        );
      }
    } else {
      navigateTo(path);
    }
  };

  const isSaveDisabled = useMemo(() => {
    return (
      checkEntitlement || envIsDisabled || !prodNameDesc.name || !prodNameDesc
    );
  }, [prodNameDesc, checkEntitlement, envIsDisabled]);

  useEffect(() => {
    if (productDetails) {
      setProdNameDesc({
        name: productDetails.name,
        desc: productDetails.desc ?? "",
      });
      const productAttributeData = deepCopy(
        getCompleteAttributeList(productDetails.ifxAcctType!)
      );
      if (productWorkflowData?.length) {
        const updatedProductAttributeData = productAttributeData.map(
          (attributeData) => ({
            ...attributeData,
            status:
              productWorkflowData?.find(
                (workflowItem: ProductWorkflowModelType) =>
                  workflowItem.stage === attributeData.componentClass
              )?.status ?? DASHBOARD_ACCORDION_STATUS.notStarted,
          })
        );
        setProductAttributesMetaData(updatedProductAttributeData);
      } else {
        const updatedProductAttributeData = productAttributeData.map(
          (attributeData: ProductSummaryType) => ({
            ...attributeData,
            status:
              productDetails.status === DASHBOARD_ACCORDION_STATUS.completed
                ? DASHBOARD_ACCORDION_STATUS.completed
                : DASHBOARD_ACCORDION_STATUS.notStarted,
          })
        );
        setProductAttributesMetaData(updatedProductAttributeData);
      }

      toggleComponentSection(!isQuickStartFlow);
      toggleEditState(isQuickStartFlow);
    } else {
      setDefaultAttributes();
    }
    //resetting sidebar menu to avoid repopulating old menu
    updateSidebarMenu?.([]);
  }, [productDetails, productWorkflowData, ifxAcctType]);

  // Fetch product when user lands
  useEffectOnce(() => {
    setProductRefetchState(true);
  });

  return (
    <Box className={css["product-summary-container"]}>
      {/* Header */}
      <Box marginTop={isEditing ? 0 : 24} px={12}>
        <Flex gap={6}>
          {!isEditing && (
            <>
              <StatusBubble status={productDetails?.status} iconWidth={24} />
              <Text as="span" fontWeight={600} fontSize={24}>
                {productDetails?.name || ""}
              </Text>
            </>
          )}
        </Flex>
        <Flex className={css["product-info"]}>
          {isEditing ? (
            <Flex flexBasis="40%">
              <Box alignSelf="flex-start" mt="35px" mr="10px">
                <StatusBubble status={productDetails?.status} iconWidth={24} />
              </Box>
              <Stack width="100%" marginRight="5%">
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="Product name*"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("name")
                        ?.tooltip_text_main
                    }
                  />
                  <InputText
                    placeholder="Enter product name"
                    disabled={Boolean(currProductId) && !isQuickStartFlow}
                    value={prodNameDesc.name}
                    onChange={_handleNameOrDescChange("name")}
                  />
                  <Box className={css["form-field-message"]}>
                    <FormFieldMsg
                      msg={"You cannot edit your product name after saving"}
                    />
                  </Box>
                </Box>

                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="Product description*"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("desc")
                        ?.tooltip_text_main
                    }
                  />
                  <InputText
                    placeholder="Enter description"
                    textarea={true}
                    value={prodNameDesc.desc}
                    onChange={_handleNameOrDescChange("desc")}
                  />
                </Box>

                <ButtonGroup gap={"1rem"} sx={{ alignSelf: "flex-end" }}>
                  {currProductId && !isQuickStartFlow ? (
                    <Button
                      onClick={() => toggleEditState(false)}
                      className="app-btn-reg-secondary"
                    >
                      Cancel
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    className="app-btn-inverse-secondary"
                    onClick={saveProductName}
                    isDisabled={isSaveDisabled}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Stack>
            </Flex>
          ) : (
            <Box sx={{ flexBasis: "40%" }}>
              <Flex position={"relative"}>
                <Text as="span" fontSize="18px" fontWeight={600}>
                  Product description
                </Text>
                <FeatureTooltip
                  content={"Edit your product description"}
                  placement={"right"}
                  customClass={"product-summary-feature-tooltip"}
                >
                  <Image
                    className={css["edit-image"]}
                    style={{ cursor: "pointer" }}
                    src={IconEdit}
                    alt="edit_icon"
                    onClick={() => toggleEditState(true)}
                  />
                </FeatureTooltip>
              </Flex>

              <Text>{productDetails?.desc || ""}</Text>
            </Box>
          )}
          <Box sx={{ flexBasis: "33%" }} gap="8px">
            <Text as="span" fontSize="18px" fontWeight={600}>
              Product type
            </Text>
            <Text>{productDetails?.prodType || "-"}</Text>
          </Box>
          <Box sx={{ flexBasis: "33%" }}>
            <Text as="span" fontSize="18px" fontWeight={600}>
              Product subtype
            </Text>
            <Text>{productDetails?.prodSubType || "-"}</Text>
          </Box>
          <Box sx={{ flexBasis: "33%" }}>
            <Text as="span" fontSize="18px" fontWeight={600}>
              Product group
            </Text>
            <Text>{productDetails?.prodGroup || "-"}</Text>
          </Box>
        </Flex>
      </Box>

      {/* Main content */}

      <Flex pl={12.8} className={`${!showComponentSection ? "disabled" : ""}`}>
        {/* Feature List - Left Section */}
        <Box className={css["product-details-section"]} pt="40px">
          <Text as="div" fontWeight={600} fontSize={24}>
            Product details
          </Text>
          <Text as="div" fontWeight={600} fontSize={20} mt="28px">
            Component details
          </Text>
          <Stack my={productDetails?.components?.length ? 12.8 : 0}>
            {componentWithMetaData.map((component) => (
              <FeatureCard
                key={component.componentClass}
                component={component}
                selected={
                  component.componentClass === selectedComponent?.componentClass
                }
                isAdditionalAttributes={false}
                handleSelectedFeature={handleComponentClick(component)}
              />
            ))}
          </Stack>
          <Box mt={productDetails?.components?.length ? 12.8 : 6.4}>
            <AddNewButton
              className={`${css["add-button"]} ${checkEntitlement || envIsDisabled ? "disabled" : ""}`}
              label="Add component"
              onClick={() => {
                navigateWithProductNameParam(
                  `${ROUTE_PATH["ADDITIONAL_FEATURES_FULLPATH"]}`
                );
              }}
            />
          </Box>
          <Text as="div" fontWeight={600} fontSize={20} mt="40px">
            Additional product attributes
          </Text>
          <Stack my={12.8}>
            {productAttributesMetaData.map((attribute) => (
              <FeatureCard
                key={attribute.name}
                component={attribute}
                selected={
                  attribute?.name === selectedAttribute?.name ? true : false
                }
                isAdditionalAttributes={true}
                handleSelectedFeature={handleAttributeClick(attribute)}
              />
            ))}
          </Stack>
        </Box>

        {/* Feature Details - Right Section*/}
        <Box flex={1} mb={-4}>
          {selectedComponent || selectedAttribute ? (
            <CardContainer customClass={css["feature-details-container"]}>
              {selectedComponent && currProductId && (
                <ProductSummaryFeatureDetails
                  type={DetailType.component}
                  detail={selectedComponent}
                  handleClose={() => {
                    setSelectedComponent(null);
                    setSelectedAttributes(null);
                  }}
                  currProductId={currProductId}
                  navigateToProductConfig={navigateToProductConfig}
                  deleteRemovedComponentsWorkflow={
                    deleteRemovedComponentsWorkflow
                  }
                />
              )}
              {selectedAttribute && currProductId && (
                <ProductSummaryFeatureDetails
                  type={DetailType.product}
                  detail={selectedAttribute}
                  handleClose={() => {
                    setSelectedComponent(null);
                    setSelectedAttributes(null);
                  }}
                  currProductId={currProductId}
                  navigateToProductConfig={navigateToProductConfig}
                  deleteRemovedComponentsWorkflow={
                    deleteRemovedComponentsWorkflow
                  }
                />
              )}
            </CardContainer>
          ) : (
            <Stack
              alignItems="center"
              pt={40}
              className={css["feature-details-continer"]}
            >
              <Box className={css["choose-continer"]}>
                <Image src={IconFeatureDetails} alt="feature details icon" />
                <Text
                  className={css["feature-details-text"]}
                  width="50%"
                  mt={7.2}
                >
                  Choose a section on the left to review and edit configuration
                  details
                </Text>
              </Box>

              <Box>
                <Image src={dotPatternWalkthrough} alt="feature details icon" />
              </Box>
            </Stack>
          )}
        </Box>
      </Flex>

      {/* Footer */}
      <AppPortal domId="#appFooter">
        <Box className="configured-product-footer">
          <Button
            className="app-btn-inverse-secondary"
            isDisabled={!productDetails?.name || !showComponentSection}
            onClick={() => navigateToProductConfig()}
            size="md"
          >
            {checkEntitlement ? "View Configuration" : "Edit Configuration"}
          </Button>
        </Box>
      </AppPortal>
    </Box>
  );
}
