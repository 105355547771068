import { Grid, GridItem } from "@chakra-ui/layout";
import css from "./AppBodyGridWithHeader.module.scss";

type Props = {
  children: [React.ReactNode, React.ReactNode];
};
const AppBodyGridWithHeader = (props: Props) => {
  return (
    <Grid className={css.container}>
      <GridItem gridRow={1}>{props.children[0]}</GridItem>
      <GridItem gridRow={2} className={css.body}>
        {props.children[1]}
      </GridItem>
    </Grid>
  );
};
export default AppBodyGridWithHeader;
